import React from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    VStack,
    HStack,
    IconButton,
    useBreakpointValue,
    Spacer,
} from '@chakra-ui/react';
import { HamburgerIcon, CheckIcon, NotAllowedIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { selectNodesLoading } from "../../redux/features/nodes/selectors";
import { useSelector, useDispatch } from 'react-redux';
import { SkippedNodesContext } from '../../App';
import { markNodeComplete, markNodeBlocked } from '../../redux/features/nodes/slice';
import { selectHighestPriorityNodeViaProjectIndexes } from '../../redux/features/projects/selectors';
import { selectConfigOrder } from '../../redux/features/config/selectors';
import { selectHighestPriorityNodeViaMasterplan } from '../../redux/features/masterplan/selectors';
import { selectProjectWithNode } from '../../redux/features/projects/selectors';
import { useContext } from 'react';
import { selectProjectsLoading } from '../../redux/features/projects/selectors';
import AccountAvatar from '../AccountTeamOverlay/AccountAvatar';

const useHandleSkip = (skippedNodes, setSkippedNodes) => {
    return (node) => {
      const newSkippedNodes = new Set(skippedNodes);
      newSkippedNodes.add(node.nodeId);
      setSkippedNodes(newSkippedNodes);
    };
  };

const MobilePage = () => {
    // Adjust button layout based on screen width
    const buttonLayout = useBreakpointValue({ base: 'column', sm: 'row' });
    const buttonWidth = buttonLayout === 'row' ? "32vw" : "100%";
    const paddingTop = buttonLayout === 'row' ? 3 : 20;
    const isNodesLoading = useSelector(selectNodesLoading);
  const isProjectsLoading = useSelector(selectProjectsLoading);
  const configOrder = useSelector(selectConfigOrder);

  const dispatch = useDispatch();

  const { skippedNodes, setSkippedNodes } = useContext(SkippedNodesContext);
  const handleSkip = useHandleSkip(skippedNodes, setSkippedNodes);
  const highestPriorityNodeViaProjectIndexes = useSelector((state) =>
    selectHighestPriorityNodeViaProjectIndexes(state, skippedNodes)
  );
  const highestPriorityNodeViaMasterplan = useSelector((state) =>
    selectHighestPriorityNodeViaMasterplan(state, skippedNodes)
  );

  const nextUpNode =
    configOrder === "projectOrder"
      ? highestPriorityNodeViaProjectIndexes
      : highestPriorityNodeViaMasterplan;

    const projects = useSelector((state) =>
      selectProjectWithNode(state, nextUpNode && nextUpNode.nodeId)
    );

  const isLoading = isNodesLoading || isProjectsLoading;

  const handleMarkNodeComplete = async (node) => {
    await dispatch(markNodeComplete(node));
  };

  const handleMarkNodeBlocked = async (node) => {
    await dispatch(markNodeBlocked(node));
  };

    return (
      <>
      <AccountAvatar />
        <Flex direction="column" p={4} height="100vh" width="100%" justify="center" align="center">
            <VStack spacing={4} flex={1} justify="center" pt={paddingTop}>
                <Text fontSize="xl" width="95%" textAlign="center">{nextUpNode && nextUpNode.name}</Text>
                <Text fontSize="xl" width="95%" fontWeight="bold" textAlign="center">{projects && projects.name}</Text>
                <Text fontSize="md" width="95%" fontWeight="light" textAlign="center">
                   {nextUpNode && nextUpNode.description}
                </Text>
                <Spacer />
                <Flex direction={buttonLayout} width="100%" justify="center">
                    <Button width={buttonWidth} leftIcon={<CheckIcon />}
                        borderRadius="7px"
                        borderWidth="1px"
                        borderColor="#266df0"
                        color="#266df0"
                        fontWeight="normal"
                        variant="outline"
                        margin={1}
                        onClick={() => handleMarkNodeComplete(nextUpNode)}
                    >
                        Complete
                    </Button>
                    <Button 
                    width={buttonWidth}
                    leftIcon={<NotAllowedIcon />} 
                        borderRadius="7px"
                        borderWidth="1px"
                        borderColor="#266df0"
                        color="#266df0"
                        fontWeight="normal"
                        variant="outline"
                        margin={1}
                        onClick={() => handleMarkNodeBlocked(nextUpNode)}
                    >
                        Block
                    </Button>
                    <Button width={buttonWidth} leftIcon={<ArrowRightIcon />} 
                    borderRadius="7px"
                    borderWidth="1px"
                    borderColor="#266df0"
                    color="#266df0"
                    fontWeight="normal"
                    variant="outline"
                    margin={1}
                    onClick={() => handleSkip(nextUpNode)}
                    >
                        Skip
                    </Button>
                </Flex>
            </VStack>
        </Flex>
        </>
    );
};

export default MobilePage;
