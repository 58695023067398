import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
} from "react-flow-renderer";
import { useParams } from "react-router-dom";
import dagre from "dagre";
import { useDispatch } from "react-redux";

import {
  selectProjectById,
  selectOpenNodesInProject,
} from "../../redux/features/projects/selectors";
import {
  selectNodes,
  selectNodesLoading,
} from "../../redux/features/nodes/selectors";
import { CustomNode } from "./CustomNode/CustomNode";
import {
  addEdgeToProject,
  addNodeToProject,
} from "../../redux/features/projects/slice";
import {
  addNodeToStateAndDatabase,
  deleteNodeInStateAndDatabase,
} from "../../redux/features/nodes/slice";
import CustomEdge from "./CustomEdgeWithoutSearch";
import {
  deleteEdgeFromProject,
  deleteNodeFromProject,
  updateProjectEdges,
} from "../../redux/features/projects/slice";
import NextUpOverlay from "../NextUpOverlay/NextUpOverlay";

//Get the information for the dagreGraph for layout formatting
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const nodeWidth = 300; // Updated node width
const nodeHeight = 80; // Updated node height

//layout the elements in the dagre style
const getLayoutedElements = (nodes, edges, direction = "LR") => {
  // Updated layout direction to 'LR'
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  if (edges) {
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  }

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  return { nodes, edges: edges || [] };
};

const NewProjectFlowChart = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const project = useSelector((state) => selectProjectById(state, projectId));
  const nodesData = useSelector(selectNodes);
  const nodesLoading = useSelector(selectNodesLoading);
  const connectingHandle = React.useRef(null);
  const connectingNodeId = React.useRef(null);

  const openNodesInProject = useSelector((state) =>
    selectOpenNodesInProject(state, projectId, [])
  );

  const edgeTypes = {
    default: CustomEdge,
  };

  //layout the nodes and edges
  const calculateFlowNodes = (project, nodesData) => {
    if (!project || !nodesData) return [];

    return project.nodes
      ? project.nodes
          .map((nodeId) => {
            if (!nodeId) {
              console.error("Undefined node found in project.nodes");
              return null;
            }

            const node = nodesData.find((n) => n.nodeId === nodeId);
            if (!node) return null;
            return {
              id: nodeId,
              type: "custom", // Use CustomNode
              data: { label: node.name || "Node", nodeId: node.nodeId }, // Assume 'project' is equivalent to 'path'
              position: { x: 0, y: 0 },
            };
          })
          .filter((node) => node)
      : [];
  };

  const calculateFlowEdges = (project) => {
    if (!project || !project.edges) return [];
    return project.edges
      ? project.edges.map((edge) => ({
          id: `e${edge.source}-${edge.target}`,
          source: edge.source,
          target: edge.target,
          // animated: true,
          // style: { stroke: '#000' }
        }))
      : [];
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    const flowNodes = calculateFlowNodes(project, nodesData);
    const flowEdges = calculateFlowEdges(project);

    return getLayoutedElements(flowNodes, flowEdges);
  }, [project, nodesData]);

  //set the nodes in the state
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  //update the nodes and edges when they change
  useEffect(() => {
    if (project && nodesData) {
      const flowNodes = calculateFlowNodes(project, nodesData);
      const flowEdges = calculateFlowEdges(project);
      const { nodes: newLayoutedNodes, edges: newLayoutedEdges } = getLayoutedElements(flowNodes, flowEdges);
      setNodes(newLayoutedNodes);
      setEdges(newLayoutedEdges);
    }
  }, [project, nodesData]);

  //[WORKING] handles creating an edge when you connect two nodes
  const onConnect = useCallback(
    async (params) => {
      const source = params.source;
      const target = params.target;
      const edge = {
        source,
        target,
      };
      await dispatch(
        addEdgeToProject({ projectId: projectId, edgeData: edge })
      );
    },
    [dispatch]
  );

  //[WORKING] handles creating a node when you double click on the background
  const onPaneClick = useCallback(
    async (event) => {
      // Add async here
      //await creating a new node
      const newNodeAction = await dispatch(
        addNodeToStateAndDatabase({ nodeToAdd: { name: "New Node" } })
      );
      //await adding the node to the project
      const newNodeId = newNodeAction.payload.nodeId;
      await dispatch(
        addNodeToProject({
          projectId: projectId,
          nodeData: { nodeId: newNodeId },
        })
      );
    },
    [dispatch, project, nodesData]
  );

  const handleEdgeClick = (event, edge) => {};

  //[WORKING] handles deleting an edge
  const onEdgesDelete = useCallback(
    async (deletedEdges) => {
      // Delete each edge concurrently
      await Promise.all(
        deletedEdges.map((edge) =>
          dispatch(
            deleteEdgeFromProject({
              projectId: projectId,
              source: edge.source,
              target: edge.target,
              projectData: project,
            })
          )
        )
      );

      //Update edges
      const updatedEdges = edges.filter(
        (edge) =>
          !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
      );
      setEdges(updatedEdges);
    },
    [edges, dispatch]
  );

  //[WORKING] handles deleting a node and adding back in the edges that need to be made
  const onNodesDelete = useCallback(
    async (deleted) => {
      if (!deleted) {
        return;
      }

      //Delete each node from the database and node state as well as project
      await Promise.all(
        deleted.map((node) =>
          Promise.all([
            dispatch(deleteNodeInStateAndDatabase(node)),
            dispatch(
              deleteNodeFromProject({
                projectId: projectId,
                nodeData: { nodeId: node.id },
                projectData: project,
              })
            ),
          ])
        )
      );

      //remove the node from the state
      const updatedNodes = nodes.filter(
        (node) => !deleted.some((deletedNode) => deletedNode.id === node.id)
      );
      setNodes(updatedNodes);

      // Update edges
      const updatedEdges = await deleted.reduce(async (accPromise, node) => {
        const acc = await accPromise;
        const incomers = getIncomers(node, nodes, edges);
        const outgoers = getOutgoers(node, nodes, edges);

        if (!incomers || !outgoers) {
          return acc;
        }

        const connectedEdges = getConnectedEdges([node], edges);

        const remainingEdges = acc.filter(
          (edge) => !connectedEdges.includes(edge)
        );

        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({
            id: `${source}->${target}`,
            source,
            target,
          }))
        );

        return [...remainingEdges, ...createdEdges];
      }, Promise.resolve(edges));

      await dispatch(
        updateProjectEdges({
          projectId: projectId,
          newEdges: updatedEdges,
          projectData: project,
        })
      );

      setEdges(updatedEdges);
    },
    [nodes, edges, dispatch]
  );

  //Gets the data on the ends that on connect end needs
  const onConnectStart = (event, { handleType, nodeId, position }) => {
    connectingHandle.current = { handleType, nodeId, position };
    connectingNodeId.current = nodeId;
  };  

  //[WORKING] handles creating a new node and edge when you drag off of a node
  const onConnectEnd = useCallback(async (event) => {
    if (event.target.className.includes("react-flow__pane")) {
      const newNodeAction = await dispatch(
        addNodeToStateAndDatabase({ nodeToAdd: { name: "New Node" } })
      );
      const newNode = newNodeAction.payload;
  
      await dispatch(
        addNodeToProject({
          projectId: projectId,
          nodeData: newNode,
        })
      );
  
      const edge = {
        source: connectingHandle.current.handleType === 'target' ? newNode.nodeId : connectingNodeId.current,
        target: connectingHandle.current.handleType === 'target' ? connectingNodeId.current : newNode.nodeId,
      };
  
      await dispatch(
        addEdgeToProject({ projectId: projectId, edgeData: edge })
      );
    }
  }, [dispatch, projectId]);
  

  if (nodesLoading) return <p>Loading...</p>; // Render loading message if nodes are loading

  return (
    <>
      <NextUpOverlay />
      <div style={{ position: 'relative', height: "100vh" }}>
      <div style={{
          position: 'absolute',
          top: '24px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 50, // Ensure it's above the flow chart
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '20px',
          // Additional styles if needed
        }}>
          {project?.name}
        </div>
        <ReactFlow
          key={projectId} // Unique key for each flow chart
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          nodeTypes={{ custom: CustomNode }}
          fitView
          onConnect={onConnect}
          onPaneClick={onPaneClick}
          onEdgeClick={handleEdgeClick}
          onEdgesDelete={onEdgesDelete}
          onNodesDelete={onNodesDelete}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
        >
          <MiniMap />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
    </>
  );
};

export default NewProjectFlowChart;
