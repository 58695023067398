import {
  selectNodes,
  selectNodesByIds,
  selectCompletedNodes,
  selectBlockedNodes,
} from "../nodes/selectors";

export const selectProjects = (state) => {
  if (!state || !state.projects || !state.projects.projects) {
    return [];
  } else {
    return state.projects.projects;
  }
};

export const selectProjectById = (state, projectId) => {
  const projects = selectProjects(state);
  if (!projects || !projects.find || !projectId) {
    return null;
  }
  return projects.find((project) => project.projectId === projectId);
};

export const selectProjectByIndex = (state, index) => {
  const projects = selectProjects(state);
  if (!projects || index < 0) {
    return null;
  }
  return projects.find((project) => project.index === index);
};

export const selectProjectsLoading = (state) => {
  return state.projects.isLoading;
};

//Selects the projects where it is not inactive or complete
export const selectActiveProjects = (state) => {
  const projects = selectProjects(state);
  if (projects.length === 0) {
    return [];
  }
  return projects.filter(
    (project) => project.status !== "inactive" && project.status !== "completed"
  );
};

export const selectInactiveProjects = (state) => {
  const projects = selectProjects(state);
  return projects.filter((project) => project.status === "inactive");
};

export const selectCompletedProjects = (state) => {
  const projects = selectProjects(state);
  return projects.filter((project) => project.status === "completed");
};

export const selectAllProjects = (state) => selectProjects(state);

export const selectAllProjectIds = (state) => {
  const projects = selectProjects(state);
  return projects.map((project) => project.projectId);
};

export const selectProjectsNotInIds = (state, projectIds) => {
  const projects = selectProjects(state);
  return projects.filter((project) => !projectIds.includes(project.projectId));
};

export const selectOpenNodesInProject = (
  state,
  project,
  skippedNodes = new Set()
) => {
  if (!project || !project.nodes) {
    return []; // Return empty array if project, nodes, or edges are undefined
  }

  const skippedNodesSet = new Set(skippedNodes);

  const nodeIds = project.nodes;
  const edges = project.edges;
  const projectNodes = selectNodesByIds(state, nodeIds) || [];
  const completedNodes = projectNodes.length
    ? projectNodes.filter((node) => node.completed)
    : [];
  const blockedNodes = projectNodes.length
    ? projectNodes.filter((node) => {
        const latestBlockedDate = new Date(node.latestBlockedDate);
        latestBlockedDate.setHours(0, 0, 0, 0); // Set time to midnight
        latestBlockedDate.setDate(latestBlockedDate.getDate() + 1); // Add a day to the latest blocked date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight
        return latestBlockedDate >= today;
      })
    : [];

  const possibleOpenNodes = projectNodes.length
    ? projectNodes.filter(
        (node) =>
          !completedNodes.includes(node) &&
          !blockedNodes.includes(node) &&
          !skippedNodesSet.has(node.nodeId)
      )
    : [];
  
    if (!project.edges || project.edges.length === 0) {
      return possibleOpenNodes;
    } else {
  const openNodes = possibleOpenNodes.length
    ? possibleOpenNodes.filter((node) => {
        const incomingEdges = edges.filter(
          (edge) => edge.target === node.nodeId
        );
        const incomingEdgeNodeIds = incomingEdges.map((edge) => edge.source);
        return incomingEdgeNodeIds.every((nodeId) => {
          const existingNode = projectNodes.find(
            (node) => node.nodeId === nodeId
          );
          return completedNodes.includes(existingNode);
        }); // Include nodes with no incoming edges
      })
    : [];

  return openNodes;
    }
};

export const selectHighestPriorityNodeViaProjectIndexes = (
  state,
  skippedNodes
) => {
  // Select active projects and sort them by index
  const projects = selectActiveProjects(state).sort((a, b) => {
    return a.index - b.index; // Assuming 'index' is a numeric property of projects
  });

  return selectHighestPriorityNodeFromProjects(state, projects, skippedNodes);
};

export const selectHighestPriorityNodeFromProjects = (
  state,
  projects,
  skippedNodes
) => {
  for (let project of projects) {
    if (project) {
      const openNodes = selectOpenNodesInProject(
        state,
        project,
        skippedNodes
      );
      if (openNodes.length > 0) {
        const openNode = {...openNodes[0], project: project};
        return openNode;
      } else {
      }
    } else {
    }
  }
  return null;
};

export const selectProjectsByIds = (state, projectsIds) => {
  const projects = selectProjects(state);
  const projectIdsSet = new Set(projectsIds);
  if (!projects || !projects.filter) {
    return [];
  }
  const actualProjects = projects.filter((project) =>
    projectIdsSet.has(project.projectId)
  );
  return actualProjects;
};

export const selectProjectWithNode = (state, nodeId) => {
  const projects = selectProjects(state);
  return projects.find((project) => project.nodes.includes(nodeId));
};

// This is for if I wanted to algorithimacly get the completion percentage

// export const selectProjectCompletionPercentage = (state, projectId) => {
//   const project = selectProjectById(state, projectId);
//   const projectNodes = selectNodesByIds(state, project.nodes);
//   const completedNodes = projectNodes.filter(node => node.completed);
//   const completionPercentage = (completedNodes.length / projectNodes.length) * 100;
//   return completionPercentage;
// };

// export const selectCompletedProjects = (state) => {
//   const projects = selectProjects(state);
//   const completedProjects = projects.filter((project) => {
//     const completionPercentage = selectProjectCompletionPercentage(state, project.projectId);
//     return completionPercentage === 100;
//   });
//   return completedProjects;
// };
