import React from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AccordionItemComponent from "./AccordionItemComponent";
import DropdownItem from "./DropdownItem";
import { updateProject } from "../../redux/features/projects/slice";
import { updateMasterplan } from "../../redux/features/masterplan/slice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const ProjectOrMasterplanGroup = ({ title, items, type, onEditClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Sort items and place items without an index at the end
  const sortedAndProcessedItems = items.slice().sort((a, b) => {
    const indexA = a.index !== undefined ? a.index : Number.MAX_SAFE_INTEGER;
    const indexB = b.index !== undefined ? b.index : Number.MAX_SAFE_INTEGER;
    return indexA - indexB;
  });

  const [sortedItems, setSortedItems] = useState(sortedAndProcessedItems);

  useEffect(() => {
    setSortedItems(sortedAndProcessedItems);
  }, [items]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(sortedItems);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    setSortedItems(reorderedItems);

    // Update order in the database
    reorderedItems.forEach((item, index) => {
      if (item.index !== index) {
        // Only update if index changed
        if (type === "project") {
          dispatch(
            updateProject({
              projectId: item.projectId,
              updatedData: { index },
            })
          );
        } else if (type === "masterplan") {
          dispatch(
            updateMasterplan({
              masterplanId: item.masterplanId,
              masterplanData: { index },
            })
          );
        }
      }
    });
  };

  return (
    <AccordionItemComponent title={title}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${type}-list`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sortedItems.map((item, index) => (
                <DropdownItem
                  key={item[`${type}Id`]}
                  item={item}
                  index={index}
                  type={type}
                  onEditClick={onEditClick}
                  navigate={navigate}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </AccordionItemComponent>
  );
};

export default ProjectOrMasterplanGroup;
