import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createEmptyNode } from "../nodes/slice"; // Import the createEmptyNode function
import {
  addProject as addProjectToDatabase,
  updateProject as updateProjectInDatabase,
  deleteProject as deleteProjectInDatabase,
  addEdgeToProject as addEdgeToProjectInDatabase,
  addNodeToProject as addNodeToProjectInDatabase,
} from "../../../services/firebaseService";

// Async thunk for adding a project
export const addProject = createAsyncThunk(
  "projects/addProject",
  async (projectData, { rejectWithValue }) => {
    try {
      const newProjectId = await addProjectToDatabase(projectData);
      return { ...projectData, projectId: newProjectId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEdgeFromProject = createAsyncThunk(
  "projects/deleteEdgeFromProject",
  async ({ projectId, source, target, projectData }, { rejectWithValue }) => {
    try {
      // Remove the edge from project.edges
      const updatedEdges = projectData.edges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );

      // Update the project in the database without the edge
      const updatedProject = await updateProjectInDatabase(projectId, {
        edges: updatedEdges,
      });

      return updatedProject;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteNodeFromProject = createAsyncThunk(
  "projects/deleteNodeFromProject",
  async ({ projectId, nodeData, projectData }, { rejectWithValue }) => {
    try {
      // Remove the node from project.nodes
      const updatedNodes = projectData.nodes.filter(
        (node) => node !== nodeData.nodeId
      );

      // Update the project in the database without the node
      const updatedProject = await updateProjectInDatabase(projectId, {
        nodes: updatedNodes,
      });

      return updatedProject;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProjectEdges = createAsyncThunk(
  "projects/updateProjectEdges",
  async ({ projectId, newEdges, projectData }, { rejectWithValue }) => {
    try {
      // Update the project in the database with the new edges
      const updatedProject = await updateProjectInDatabase(projectId, {
        edges: newEdges,
      });

      return updatedProject;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProject = createAsyncThunk(
  "projects/updateProject",
  async ({ projectId, updatedData }, { rejectWithValue }) => {
    try {
      const updatedProject = await updateProjectInDatabase(
        projectId,
        updatedData
      );
      return updatedProject;
    } catch (error) {
      console.error("Error updating project", error); // Log any error
      return rejectWithValue(error);
    }
  }
);

export const addEmptyProjectWithNode = createAsyncThunk(
  "projects/addEmptyProjectWithNode",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      // Add a new project
      const newProject = await addProjectToDatabase({
        name: "New Project",
        status: "active",
      });

      // Create an empty node
      const newNodeAction = await dispatch(createEmptyNode("New Node")); // Use the createEmptyNode function

      const newNode = newNodeAction.payload;
      const newNodeId = newNode.nodeId;

      // Add the new node to the project
      const newProjectWithNodeAction = await addNodeToProjectInDatabase(
        newProject.projectId,
        { nodeId: newNodeId }
      );

      // Return the updated project
      // const newProject = await updateProjectInDatabase(newProjectId, { nodes: [newNodeId] });
      return newProjectWithNodeAction;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEdgeToProject = createAsyncThunk(
  "projects/addEdgeToProject",
  async ({ projectId, edgeData }, { rejectWithValue }) => {
    try {
      const updatedProject = await addEdgeToProjectInDatabase(
        projectId,
        edgeData
      );
      return updatedProject;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNodeToProject = createAsyncThunk(
  "projects/addNodeToProject",
  async ({ projectId, nodeData }, { rejectWithValue }) => {
    try {
      const updatedProject = await addNodeToProjectInDatabase(
        projectId,
        nodeData
      );

      return updatedProject;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (projectId) => {
    await deleteProjectInDatabase(projectId);
    return projectId;
  }
);

const initialState = {
  projects: [],
  isLoading: false,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProjectName: (state, action) => {
      const { projectId, name } = action.payload;
      const projectIndex = state.projects.findIndex(
        (project) => project.projectId === projectId
      );
      if (projectIndex !== -1) {
        state.projects[projectIndex].name = name;
        updateProjectInDatabase(projectId, { name });
      }
    },
    addNode: (state, action) => {
      const { node } = action.payload;
      state.nodes[node.nodeId] = node; // Assuming node.nodeId is the key
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle addProject
      .addCase(addProject.fulfilled, (state, action) => {
        state.projects.push(action.payload);
      })

      .addCase(addProject.rejected, (_, action) => {
        console.error("addProject was not fulfilled:", action.error);
      })

      // [WORKING PAYLOAD] Handle deleteEdgeFromProject
      .addCase(deleteEdgeFromProject.fulfilled, (state, action) => {
        const updatedProject = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.projectId === updatedProject.projectId
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex].edges = updatedProject.edges;
        }
      })

      // [WORKING PAYLOAD] Handle deleteNodeFromProject
      .addCase(deleteNodeFromProject.fulfilled, (state, action) => {
        const updatedProject = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.projectId === updatedProject.projectId
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex].nodes = updatedProject.nodes;
        }
      })

      // [WORKING PAYLOAD] Handle updateProjectEdges
      .addCase(updateProjectEdges.fulfilled, (state, action) => {
        const updatedProject = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.projectId === updatedProject.projectId
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex].edges = updatedProject.edges;
        }
      })

      // [WORKING PAYLOAD] Handle updateProject
      .addCase(updateProject.fulfilled, (state, action) => {
        const updatedProject = action && action.payload;
        if (updatedProject) {
          const projectIndex = state.projects.findIndex(
            (project) => project.projectId === updatedProject.projectId
          );
          if (projectIndex !== -1) {
            state.projects[projectIndex] = updatedProject;
          }
        }
      })

      // [WORKING PAYLOAD] Handle addEmptyProjectWithNode
      .addCase(addEmptyProjectWithNode.fulfilled, (state, action) => {
        state.projects.push(action.payload);
      })

      // Handle addEmptyProjectWithNode rejected case
      .addCase(addEmptyProjectWithNode.rejected, (_, action) => {
        console.error("Failed to add empty project with node:", action.error);
      })

      // [WORKING PAYLOAD] Handle addEdgeToProject
      .addCase(addEdgeToProject.fulfilled, (state, action) => {
        const updatedProject = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.projectId === updatedProject.projectId
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex].edges = updatedProject.edges;
        }
      })

      .addCase(addEdgeToProject.rejected, (_, action) => {
        console.error("addEdgeToProject was not fulfilled:", action.error);
      })

      // [WORKING PAYLOAD] Handle addNodeToProject
      .addCase(addNodeToProject.fulfilled, (state, action) => {
        const updatedProject = action.payload;
        const projectIndex = state.projects.findIndex(
          (project) => project.projectId === updatedProject.projectId
        );
        if (projectIndex !== -1) {
          state.projects[projectIndex].nodes = updatedProject.nodes;
        }
      })
      // [WORKING PAYLOAD]
      .addCase(deleteProject.fulfilled, (state, action) => {
        const projectIdToDelete = action.payload;
        state.projects = state.projects.filter(
          (project) => project.projectId !== projectIdToDelete
        );
      })
      // Handle deleteProject rejected case
      .addCase(deleteProject.rejected, (_, action) => {
        console.error("Failed to delete project:", action.error);
      })

      .addCase(addNodeToProject.rejected, (_, action) => {
        console.error("addNodeToProject was not fulfilled:", action.error);
      })
      // Handle deleteEdgeFromProject rejected case
      .addCase(deleteEdgeFromProject.rejected, (_, action) => {
        console.error("Failed to delete edge from project:", action.error);
      })

      // Handle deleteNodeFromProject rejected case
      .addCase(deleteNodeFromProject.rejected, (_, action) => {
        console.error("Failed to delete node from project:", action.error);
      })

      // Handle updateProjectEdges rejected case
      .addCase(updateProjectEdges.rejected, (_, action) => {
        console.error("Failed to update project edges:", action.error);
      })

      // Handle updateProject rejected case
      .addCase(updateProject.rejected, (_, action) => {
        console.error("Failed to update project:", action.error);
      });

    // Add any other thunks handling as required
  },
});

export const { updateProjectName, setProjects, setLoading } =
  projectsSlice.actions;

export default projectsSlice.reducer;
