const NodeWrapper = ({
  children,
  backgroundColor,
  borderColor,
  openNodeModal,
  node
}) => {

  // Function to handle the double-click event
  const handleDoubleClick = () => {
    openNodeModal(node);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: "10px",
        overflow: "hidden", // Prevents content from spilling outside
      }}
      onDoubleClick={handleDoubleClick} // Attach the double-click event handler
    >
      {children}
    </div>
  );
};

export default NodeWrapper;
