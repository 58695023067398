import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectAllMasterplans } from "../../redux/features/masterplan/selectors";
import MasterplanFlow from "./MasterplanFlow";
import CardTray from "./CardTray";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import NextUpOverlay from "../NextUpOverlay/NextUpOverlay";
import { useEdgesState, useNodesState } from "react-flow-renderer";
import { Box, useColorModeValue as mode } from "@chakra-ui/react";

const MasterPlan = () => {
  const { masterplanId } = useParams();
  const masterplans = useSelector(selectAllMasterplans);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  // Render nothing if conditions aren't met
  if (!masterplans || !masterplans[0] || !masterplans[0].masterplanId) {
    return null;
  }

  return (
    <>
      <NextUpOverlay />
      <DragDropContext>
        <CardTray
          style={{
            position: "fixed",
            top: "5rem",
            right: "1rem",
            zIndex: 1000,
          }}
          masterplanId={masterplanId}
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
        />
        <Box bg={mode("white", "gray.800")} flex="1" position="relative">
          <MasterplanFlow 
            masterplanId={masterplanId}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            nodes={nodes}
            setNodes={setNodes}
            edges={edges}
            setEdges={setEdges}
          />
        </Box>
      </DragDropContext>
    </>
  );
};

export default MasterPlan;
