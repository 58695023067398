import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  List,
  ListItem,
  ListIcon,
  LinkBox,
  LinkOverlay,
  Container,
  useToken,
  Center,
  Text,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { selectProjects } from "../../redux/features/projects/selectors";

const ProjectCard = ({ project, navigate }) => (
  <LinkBox
    as="article"
    p="5"
    borderWidth="1px"
    rounded="md"
    w="full"
    _hover={{ bg: "gray.100" }}
  >
    <Heading size="md">
      <LinkOverlay
        href="#"
        onClick={() => navigate(`/project-flow/${project.projectId}`)}
      >
        {project.name}
      </LinkOverlay>
    </Heading>
    <Box display="flex" alignItems="center">
      <Text fontSize="sm" color="gray.500">
        Project ID: {project.projectId}
      </Text>
    </Box>
  </LinkBox>
);

const ProjectListSection = ({ title, projects }) => {
  const navigate = useNavigate();
  return (
    <Box w="full">
      <Heading size="lg" my={4}>
        {title}
      </Heading>
      <VStack spacing={4}>
        {projects.map((project) => (
          <ProjectCard
            key={project.projectId}
            project={project}
            navigate={navigate}
          />
        ))}
      </VStack>
    </Box>
  );
};

const ProjectPage = () => {
  const activeProjects = useSelector(selectProjects);

  const [width80Percent] = useToken("sizes", ["80%"]); // Getting 80% size token

  return (
    <Center mt={100} mb={100}>
      <VStack
        w={width80Percent} // Using the width token
        spacing={8}
        align="stretch"
        overflowY="auto"
        maxH="calc(100vh - 200px)" // Adjust if needed based on new margin
      >
        <ProjectListSection title="Active Projects" projects={activeProjects} />
      </VStack>
    </Center>
  );
};

export default ProjectPage;
