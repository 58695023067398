import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box, Flex, useColorModeValue as mode } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";
import MasterPlan from "./components/Masterplan/MasterPlan";
import ProjectOverlay from "./components/ProjectOverlay/ProjectOverlay";
import ProjectPage from "./components/ProjectsPage/ProjectPage";
import AccountTeamOverlay from "./components/AccountTeamOverlay/AccountTeamOverlay";
import PeoplePage from "./components/PeoplePage/PeoplePage";
import NewProjectFlowChart from "./components/FlowChart/NewProjectFlowChart"; // Import NewProjectFlowChart component
import ProjectModal from "./components/ProjectOverlay/ProjectModal";
import { createContext, useState } from "react";
import MasterplanModal from "./components/ProjectOverlay/MasterplanModal";
import PersonModal from "./components/PeoplePage/PersonModal";
import useWindowSize from './components/Mobile/WindowSize';
import MobilePage from "./components/Mobile/MobilePage";

import NodeModal from "./components/FlowChart/CustomNode/NodeModal"; 
import AccountModal from "./components/AccountTeamOverlay/AccountModal";


const queryClient = new QueryClient();

export const ProjectModalContext = createContext();
export const MasterplanModalContext = createContext();
export const PersonModalContext = createContext();
export const SkippedNodesContext = createContext();
export const ForceUpdateContext = createContext();
export const NodeModalContext = createContext();
export const AccountModalContext = createContext();

function App() {
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const [isMasterplanModalOpen, setIsMasterplanModalOpen] = useState(false);
  const [masterplanToEdit, setMasterplanToEdit] = useState(null);
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [personToEdit, setPersonToEdit] = useState(null);
  const [skippedNodes, setSkippedNodes] = useState(new Set());
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isNodeModalOpen, setIsNodeModalOpen] = useState(false);
  const [nodeToEdit, setNodeToEdit] = useState(null);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState(null);

  const { width } = useWindowSize();
  const mobileBreakpoint = 768; // Define your mobile breakpoint

  // Function to open the project modal
  const openProjectModal = (project) => {
    setProjectToEdit(project);
    setIsProjectModalOpen(true);
  };

  // Function to close the project modal
  const closeProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectToEdit(null);
  };

  // Function to open the masterplan modal
  const openMasterplanModal = (masterplan) => {
    setMasterplanToEdit(masterplan);
    setIsMasterplanModalOpen(true);
  };

  // Function to close the masterplan modal
  const closeMasterplanModal = () => {
    setIsMasterplanModalOpen(false);
    setMasterplanToEdit(null);
  };

  // Function to open the person modal
  const openPersonModal = (person) => {
    setPersonToEdit(person);
    setIsPersonModalOpen(true);
  };

  // Function to close the person modal
  const closePersonModal = () => {
    setIsPersonModalOpen(false);
    setPersonToEdit(null);
  };
  const openNodeModal = (node) => {
    setNodeToEdit(node);
    setTimeout(() => setIsNodeModalOpen(true), 0);
};

  const closeNodeModal = () => {
    setIsNodeModalOpen(false);
    setNodeToEdit(null);
  };

  const openAccountModal = (account) => {
    setAccountToEdit(account);
    setIsAccountModalOpen(true);
  }

  const closeAccountModal = () => {
    setIsAccountModalOpen(false);
    setAccountToEdit(null);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <ForceUpdateContext.Provider value={{ forceUpdate, setForceUpdate }}>
          <SkippedNodesContext.Provider value={{ skippedNodes, setSkippedNodes }}>
          <NodeModalContext.Provider value={{ openNodeModal, closeNodeModal }}>
            <AccountModalContext.Provider value={{ openAccountModal, closeAccountModal }}>
            <ProjectModalContext.Provider value={{ openProjectModal, closeProjectModal }}>
              <MasterplanModalContext.Provider value={{ openMasterplanModal, closeMasterplanModal }}>
                <PersonModalContext.Provider value={{ openPersonModal, closePersonModal }}>
                
                  {width <= mobileBreakpoint ? (
                    <>
                    <MobilePage />
                    <AccountModal isOpen={isAccountModalOpen} onClose={closeAccountModal} account={accountToEdit} />
                    </>
                  ) : (
                    <Router>
                      <ProjectModal isOpen={isProjectModalOpen} onClose={closeProjectModal} project={projectToEdit} />
                      <MasterplanModal isOpen={isMasterplanModalOpen} onClose={closeMasterplanModal} masterplan={masterplanToEdit} />
                      <PersonModal isOpen={isPersonModalOpen} onClose={closePersonModal} person={personToEdit} />
                      <NodeModal isOpen={isNodeModalOpen} onClose={closeNodeModal} node={nodeToEdit} />
                      <AccountModal isOpen={isAccountModalOpen} onClose={closeAccountModal} account={accountToEdit} />
                      <Box height="100vh" overflow="hidden" position="relative">
                        <Flex h="full" id="app-container">
                          <ProjectOverlay />
                          <AccountTeamOverlay />
                          <Box bg={mode("white", "gray.800")} flex="1">
                            <Routes>
                              <Route exact path="/" element={<ProjectPage />} />
                              <Route path="/flow/masterplan/:masterplanId" element={<MasterPlan />} />
                              <Route path="/projects" element={<ProjectPage />} />
                              <Route path="/flow/project/:projectId" element={<NewProjectFlowChart />} />
                              <Route path="/people/managers" element={<PeoplePage viewMode="manager" />} />
                              <Route path="/people/assignees" element={<PeoplePage viewMode="assignee" />} />
                            </Routes>
                          </Box>
                        </Flex>
                      </Box>
                    </Router>
                  )}
                  
                </PersonModalContext.Provider>
              </MasterplanModalContext.Provider>
            </ProjectModalContext.Provider>
            </AccountModalContext.Provider>
            </NodeModalContext.Provider>
          </SkippedNodesContext.Provider>
        </ForceUpdateContext.Provider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
