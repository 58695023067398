import React, { useState, useEffect } from "react";
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { useDisclosure } from "@chakra-ui/react";
const hitAreaPadding = 10; // Adjust the hit area padding as needed

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEndId,
}) => {
  const { isOpen } = useDisclosure();
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd("arrowclosed", markerEndId);
  const [isHovered, setIsHovered] = useState(false);

  // Adjust this handler to ensure the button remains visible
  const handleMouseMove = (event) => {
    event.preventDefault();
    setIsHovered(true);
  };

  const handleMouseLeave = (event) => {
    event.preventDefault();
    setIsHovered(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsHovered(false);
    }
  }, [isOpen]);

  return (
    <>
      {/* Visible edge path */}
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {/* Invisible hit area to increase hit area */}
      <path
        style={{
          ...style,
          strokeWidth: hitAreaPadding * 2,
          stroke: "transparent",
        }}
        className="react-flow__edge-path-selector"
        d={edgePath}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};

export default CustomEdge;
