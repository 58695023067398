import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Editable,
  EditablePreview,
  EditableInput,
  ModalCloseButton,
  ModalBody,
  Button,
  ButtonGroup,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  updateProject,
  deleteProject,
} from "../../redux/features/projects/slice";
import { useNavigate } from "react-router-dom";
import { Select } from "@chakra-ui/react";

const ProjectModal = ({ isOpen, onClose, project }) => {
  const [projectName, setProjectName] = useState("");
  const [status, setStatus] = useState("active");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isNewProject, setIsNewProject] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (project) {
      setProjectName(project.name);
      setStatus(project.status || "active");
      setIsEditingName(false); // Reset editing state on project change
    } else {
      setProjectName("New Project");
      setStatus("active");
      setIsNewProject(true);
    }
  }, [project]);

  const handleProjectUpdate = async () => {
    try {
      await dispatch(
        updateProject({
          projectId: project.projectId,
          updatedData: { name: projectName, status },
        })
      );
      onClose();
      // if (isNewProject) {
      //   navigate(`/flow/project/${project.projectId}`);
      // }
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const handleDelete = async () => {
    if (project) {
      await dispatch(deleteProject(project.projectId));
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderWidth="2px" borderRadius="10px">
        <ModalHeader>
          <h2>Edit Project</h2>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} width="100%">
            <HStack width="100%" spacing={2}>
              <Editable
                width="100%"
                value={projectName}
                isEditing={isEditingName}
                onEdit={() => setIsEditingName(true)}
                onCancel={() => setIsEditingName(false)}
                onSubmit={() => setIsEditingName(false)} // Keep saving logic out of here
              >
                <EditablePreview fontSize="lg" />
                <EditableInput
                  onChange={(e) => setProjectName(e.target.value)}
                  fontSize="lg"
                  width="100%"
                  borderRadius="5px"
                  fontWeight="semibold"
                  padding="0.5rem"
                  border="2px solid gray"
                />
              </Editable>
            </HStack>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              //   onClick={(e) => e.stopPropagation()}
              width="100%"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="completed">Completed</option>
            </Select>
            <ButtonGroup spacing={2} width="100%">
              <Button colorScheme="blue" flex="1" onClick={handleProjectUpdate}>
                Save
              </Button>
              <Button colorScheme="red" flex="1" onClick={handleDelete}>
                Delete Project
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectModal;
