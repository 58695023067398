import { Handle } from "react-flow-renderer";

const CustomHandle = ({
  type,
  position,
  id = null,
  onConnect,
  borderColor,
}) => {
  // Default style for the handle which is not visible
  let handleStyle = {
    opacity: 0, // Make handle invisible
    // other shared styles...
  };

  // If the position is 'right', change the style to make it visible
  if (position === "right") {
    handleStyle = {
      ...handleStyle, // Spread in the default styles
      width: "8px",
      height: "8px",
      backgroundColor: "white",
      border:
        borderColor === "#D5D7D9"
          ? "1px solid #266DF0"
          : `1px solid ${borderColor}`,
      borderRadius: "50%",
      opacity: 1, // Make handle visible
      top: "50%",
      transform: "translateY(-50%)",
    };
  }

  return (
    <Handle
      type={type}
      position={position}
      id={id}
      onConnect={onConnect}
      style={handleStyle}
    />
  );
};

export default CustomHandle;
