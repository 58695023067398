import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Editable,
  EditablePreview,
  EditableInput,
  EditableTextarea,
  Select,
  Divider,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { CheckIcon, NotAllowedIcon, DeleteIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllPeople } from '../../../redux/features/people/selectors';
import { updateNodeInStateAndDatabase, markNodeComplete, markNodeBlocked, deleteNodeInStateAndDatabase } from '../../../redux/features/nodes/slice';
import { SkippedNodesContext } from '../../../App'; // Ensure this path matches your project structure
import { useContext } from 'react';
const NodeModal = ({
  isOpen,
  onClose,
  node,
}) => {
    const dispatch = useDispatch();
    const allPeople = useSelector(selectAllPeople);
    const [nodeName, setNodeName] = useState('');
    const [nodeDescription, setNodeDescription] = useState('');
    const [selectedManagerId, setSelectedManagerId] = useState('');
    const [selectedAssigneeId, setSelectedAssigneeId] = useState('');

    const { skippedNodes, setSkippedNodes } = useContext(SkippedNodesContext);

    // Handle skipping the node
    const handleSkip = () => {
      const newSkippedNodes = new Set(skippedNodes);
      newSkippedNodes.add(node.nodeId);
      setSkippedNodes(newSkippedNodes);
      onClose(); // Optionally close the modal after skipping
    };

      // UseEffect to update state when the node prop changes
      useEffect(() => {
        if (node) {
            setNodeName(node.name || '');
            setNodeDescription(node.description || '');
            setSelectedManagerId(node.manager || '');
            setSelectedAssigneeId(node.assignee || '');
        }
    }, [node]);
    

  if (!node) {
    return null;
  }
  const handleSaveNode = () => {
    dispatch(updateNodeInStateAndDatabase({
      nodeId: node.nodeId,
      updateData: {
        name: nodeName,
        description: nodeDescription,
        manager: selectedManagerId,
        assignee: selectedAssigneeId,
      },
    }));
    onClose(); // Close the modal after saving
  };

  // Handlers for Complete, Block, Skip actions
  const handleComplete = () => dispatch(markNodeComplete(node));
  const handleBlock = () => dispatch(markNodeBlocked(node));
  const handleDeleteNode = () => {
    dispatch(deleteNodeInStateAndDatabase(node));
    onClose(); // Close the modal after deletion
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderWidth="2px" borderRadius="10px" borderColor={"#D5D7D9"}>
        <ModalHeader>Edit Node</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={1} alignItems="left">
            <Text >Node Name:</Text>
            <Editable defaultValue={nodeName} width="100%" onSubmit={setNodeName}>
              <EditablePreview />
              <EditableInput value={nodeName} onChange={(e) => setNodeName(e.target.value)} />
            </Editable>

            <Text>Description:</Text>
            <Editable defaultValue={nodeDescription} width="100%" onSubmit={setNodeDescription}>
              <EditablePreview />
              <EditableTextarea value={nodeDescription} onChange={(e) => setNodeDescription(e.target.value)} />
            </Editable>

            <Divider my={4} />

            <Text mb={2}>Manager:</Text>
            <Select placeholder="Select manager" value={selectedManagerId} onChange={(e) => setSelectedManagerId(e.target.value)}>
              {allPeople.map((person) => (
                <option key={person.personId} value={person.personId}>{person.firstName} {person.lastName}</option>
              ))}
            </Select>

            <Text mb={2}>Assignee:</Text>
            <Select placeholder="Select assignee" value={selectedAssigneeId} onChange={(e) => setSelectedAssigneeId(e.target.value)}>
              {allPeople.map((person) => (
                <option key={person.personId} value={person.personId}>{person.firstName} {person.lastName}</option>
              ))}
            </Select>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack spacing={2} width="100%">
            <HStack spacing={2} width="100%">
              <Button
                leftIcon={<CheckIcon />}
                width="100%"
                borderRadius="7px"
                borderWidth="1px"
                borderColor="#266df0"
                color="#266df0"
                fontWeight="normal"
                variant="outline"
                onClick={handleComplete}>Complete</Button>
              <Button
                leftIcon={<NotAllowedIcon />}
                width="100%"
                borderRadius="7px"
                borderWidth="1px"
                borderColor="#266df0"
                color="#266df0"
                fontWeight="normal"
                variant="outline"
                onClick={handleBlock}>Block</Button>
              <Button
                leftIcon={<ArrowRightIcon />}
                width="100%"
                borderRadius="7px"
                borderWidth="1px"
                borderColor="#266df0"
                color="#266df0"
                fontWeight="normal"
                variant="outline"
                onClick={handleSkip}>Skip</Button>
            </HStack>
            <HStack spacing={2} width="100%">
              <Button
                width="100%"
                borderRadius="7px"
                borderWidth="1px"
                borderColor="#266df0"
                color="#266df0"
                fontWeight="normal"
                variant="outline"
                onClick={handleSaveNode}>Save</Button>
              {/* <Button
                leftIcon={<DeleteIcon />}
                width="100%"
                borderRadius="7px"
                borderWidth="1px"
                borderColor="#266df0"
                color="#266df0"
                fontWeight="normal"
                variant="outline"
                onClick={handleDeleteNode}>Delete Node</Button> */}
            </HStack>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NodeModal;
