// RadioButtonsGroup.js
import { VStack, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "./RadioCard";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectConfigOrder } from "../../redux/features/config/selectors";

function RadioButtonsGroup({ handleChangeOrder }) {
  const configOrder = useSelector(selectConfigOrder);
  const options = ["Activated Masterplan", "Project Order"];

  const handleOrderSwitch = (order) => {
    if (order === "Activated Masterplan") {
      handleChangeOrder("masterplan");
    } else if (order === "Project Order") {
      handleChangeOrder("projectOrder");
    } else {
      console.error("Error: invalid order");
    }
  };

  const defaultOrder = (order) => {
    if (order === "masterplan") {
      return "Activated Masterplan";
    } else if (order === "projectOrder") {
      return "Project Order";
    } else {
      console.error("Error: invalid order");
    }
  };

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: "order",
    defaultValue: null,
    onChange: (value) => handleOrderSwitch(value),
  });

  useEffect(() => {
    const defaultOrderValue = defaultOrder(configOrder) || null;
    setValue(defaultOrderValue);
  }, [configOrder, setValue]);

  const group = getRootProps();

  return (
    <VStack
      {...group}
      height="80px"
      width="200px"
      borderWidth="1px"
      borderColor="#266df0"
      borderRadius="10px 0 0 10px" // Only rounded on the left
      color="#266df0"
      spacing={0}
      overflow="hidden" // Prevents child border radius from showing
    >
      {options.map((value, index) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard
            key={value}
            {...radio}
            isFirst={index === 0}
            isLast={index === options.length - 1}
          >
            {value}
          </RadioCard>
        );
      })}
    </VStack>
  );
}

export default RadioButtonsGroup;
