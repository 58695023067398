import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  updateNode as updateNodeInDatabase,
  deleteNode as deleteNodeInDatabase,
  addNode as addNodeToDatabase,
} from "../../../services/firebaseService";
import { selectNodeById, selectActiveProjects } from "./selectors";

const initialState = {
  nodes: {},
  isLoading: false,
  projects: [],
  projectGroups: {},
  projectPairs: [],
  skippedNodes: [],
  highestPriortyOpenNode: null,
};

const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setHighestPriorityOpenNode: (state, action) => {
      state.highestPriorityOpenNode = action.payload;
    },
    addNode: (state, action) => {
      const { node } = action.payload;
      state.nodes[node.nodeId] = node; // Assuming node ID is unique and used as key
    },
    updateNode: (state, action) => {
      const { nodeId, updatedNode } = action.payload;
      if (state.nodes[nodeId]) {
        state.nodes[nodeId] = { ...state.nodes[nodeId], ...updatedNode };
      }
    },
    deleteNode: (state, action) => {
      const nodeId = action.payload;
      if (state.nodes[nodeId]) {
        state.nodes[nodeId] = undefined; // Set the node to undefined
      }
    },    
    addSkippedNode: (state, action) => {
      const { nodeId } = action.payload;
      state.skippedNodes.push(nodeId);
    },
    // ... (other reducers)
  },
  extraReducers: (builder) => {
    builder
      // Handle markNodeComplete
      .addCase(markNodeComplete.fulfilled, (state, action) => {
        const updatedNode = action.payload;
        const nodeIndex = state.nodes.findIndex(node => node.nodeId === updatedNode.nodeId);
        if (nodeIndex !== -1) {
          state.nodes[nodeIndex] = updatedNode;
        }
      })
      .addCase(markNodeComplete.rejected, (state, action) => {
        console.error("markNodeComplete failed:", action.error);
      })

      // Handle markNodeBlocked
      .addCase(markNodeBlocked.fulfilled, (state, action) => {
        const updatedNode = action.payload;
        const nodeIndex = state.nodes.findIndex(node => node.nodeId === updatedNode.nodeId);
        if (nodeIndex !== -1) {
          state.nodes[nodeIndex] = updatedNode;
        }
      })
      .addCase(markNodeBlocked.rejected, (state, action) => {
        console.error("markNodeBlocked failed:", action.error);
      })

      // [WORKING PAYLOAD] Handle createEmptyNode
      .addCase(createEmptyNode.fulfilled, (state, action) => {
        const newNode = action.payload;
        state.nodes.push(newNode);
      })
      .addCase(createEmptyNode.rejected, (state, action) => {
        console.error("createEmptyNode failed:", action.error);
      })

      // [WORKING PAYLOAD] Handle addNodeToStateAndDatabase
      .addCase(addNodeToStateAndDatabase.fulfilled, (state, action) => {
        const newNode = action.payload;
        state.nodes.push(newNode);
      })
      .addCase(addNodeToStateAndDatabase.rejected, (state, action) => {
        console.error("addNodeToStateAndDatabase failed:", action.error);
      })

      // Handle updateNodeInStateAndDatabase
      .addCase(updateNodeInStateAndDatabase.fulfilled, (state, action) => {
        const updatedNode = action.payload;
        const nodeIndex = state.nodes.findIndex(node => node.nodeId === updatedNode.nodeId);
        if (nodeIndex !== -1) {
          state.nodes[nodeIndex] = updatedNode;
        }
      })
      .addCase(updateNodeInStateAndDatabase.rejected, (state, action) => {
        console.error("updateNodeInStateAndDatabase failed:", action.error);
      })

      // [WORKING PAYLOAD] Handle updateNodeName
      .addCase(updateNodeName.fulfilled, (state, action) => {
        const updatedNode = action.payload;
        const nodeIndex = state.nodes.findIndex(node => node.nodeId === updatedNode.nodeId);
        if (nodeIndex !== -1) {
          state.nodes[nodeIndex] = updatedNode;
        }
      })
      .addCase(updateNodeName.rejected, (state, action) => {
        console.error("updateNodeName failed:", action.error);
      })

      .addCase(deleteNodeInStateAndDatabase.fulfilled, (state, action) => {
        const deletedNodeId = action.payload.nodeId;
        // Check if the node exists in the state and delete it
        if (state.nodes[deletedNodeId]) {
          delete state.nodes[deletedNodeId];
        } else {
          console.warn(`Node with id ${deletedNodeId} not found in state.`);
        }
      })
      .addCase(deleteNodeInStateAndDatabase.rejected, (state, action) => {
        console.error("Failed to delete node:", action.error.message);
      })
      
      .addCase(removePersonFromNodes.fulfilled, (state, action) => {
        const personId = action.payload; // Assuming the payload is the ID of the person being removed
        Object.values(state.nodes).forEach(node => {
          if (node.manager === personId) {
            node.manager = null; // Reset manager if it was the removed person
          }
          if (node.assignee === personId) {
            node.assignee = null; // Reset assignee if it was the removed person
          }
        });
      })
      .addCase(removePersonFromNodes.rejected, (state, action) => {
        console.error("removePersonFromNodes failed:", action.error);
      });
  },
});

export const markNodeComplete = createAsyncThunk(
  "nodes/markNodeComplete",
  async (node, { dispatch }) => {
    // Add dispatch here
    const updatedNode = { ...node, completed: !node.completed };
    const updatedNodeId = updatedNode.nodeId;
    await dispatch(updateNodeInStateAndDatabase({ nodeId: updatedNodeId, updateData: updatedNode }));
    return updatedNode;
  }
);

export const markNodeBlocked = createAsyncThunk(
  "nodes/markNodeBlocked",
  async (node, { dispatch }) => {
    // Add dispatch here
    const today = new Date().toISOString().split("T")[0];
    const latestBlockedDate = node.latestBlockedDate === today ? null : today;
    const updatedNode = { ...node, latestBlockedDate: latestBlockedDate };
    const updatedNodeId = updatedNode.nodeId;
    await dispatch(updateNodeInStateAndDatabase({ nodeId: updatedNodeId, updateData: updatedNode }));
    return updatedNode;
  }
);

export const addNodeToStateAndDatabase = createAsyncThunk(
  "nodes/addNodeToStateAndDatabase",
  async ({ nodeToAdd }, { getState, dispatch, rejectWithValue }) => {
    try {
      const node = await addNodeToDatabase(nodeToAdd);
      // dispatch(addNode({ node: node }));
      return node; // Ensure this returns the full node object
    } catch (error) {
      console.error("Failed to add node to the database:", error);
      return rejectWithValue(error); // Handling the error in Redux state
    }
  }
);

export const createEmptyNode = createAsyncThunk(
  "nodes/createEmptyNode",
  async (nodeName, { getState, dispatch }) => {
    const newNode = {
      name: nodeName || "New Node",
      completed: false,
      latestBlockedDate: null,
    };

    // Add the new empty node to the state and database
    try {
      const resultAction = await dispatch(addNodeToStateAndDatabase({ nodeToAdd: newNode }));
      const nodeToAdd = resultAction.payload; // Access the payload from the result action
      return nodeToAdd;
    } catch (error) {
      console.error("Failed to create empty node:", error);
    }
  }
);

export const updateNodeInStateAndDatabase = createAsyncThunk(
  "nodes/updateNodeInStateAndDatabase",
  async ({ nodeId, updateData }, { rejectWithValue }) => {
    try {
      // Call a function to update the node in the database
      const updatedNode = await updateNodeInDatabase(nodeId, updateData);

      // Return the updated node data
      return updatedNode;
    } catch (error) {
      console.error("Error updating node", error); // Log any error
      return rejectWithValue(error);
    }
  }
);

export const updateNodeName = createAsyncThunk(
  "nodes/updateNodeName",
  async ({ nodeId, newName }, { getState, dispatch }) => {
    const nodeToUpdate = selectNodeById(getState(), nodeId);

    // Create the updated node
    const updatedNode = { ...nodeToUpdate, name: newName };

    // Update the node in the database
    try {
      const updatedNodeNew = await updateNodeInDatabase(updatedNode.nodeId, updatedNode);
      // If successful, update the node in the Redux state
      dispatch(updateNode({ nodeId: updatedNode.nodeId, updatedNode }));
      return updatedNodeNew
    } catch (error) {
      console.error("Failed to update node in the database:", error);
    }
  }
);

export const deleteNodeInStateAndDatabase = createAsyncThunk(
  "nodes/deleteNodeInStateAndDatabase",
  async (node, { getState, dispatch }) => {
    try {
      const deletedNode = await deleteNodeInDatabase(node);

      // Return the nodeId of the deleted node as the payload
      return deletedNode;
    } catch (error) {
      console.error("Failed to delete node in the database:", error);
      throw error; // Make sure to propagate the error
    }
  }
);


export const removePersonFromNodes = createAsyncThunk(
  "nodes/removePersonFromNodes",
  async (personId, { dispatch, getState }) => {
    const state = getState();
    const nodesToUpdate = state.nodes.nodes.filter(
      (node) => node.manager === personId || node.assignee === personId
    );

    nodesToUpdate.forEach((node) => {
      let updatedNode = { ...node };
      if (updatedNode.manager === personId) {
        updatedNode.manager = ""; // or another default value
      }
      if (updatedNode.assignee === personId) {
        updatedNode.assignee = ""; // or another default value
      }
      dispatch(
        updateNodeInStateAndDatabase({ nodeId: node.nodeId, updatedNode })
      );
    });
  }
);

export const updateNodes = createAction("nodes/updateNodes");

export const {
  addNode,
  updateNode,
  deleteNode,
  setNodes,
  setLoading,
  addSkippedNode,
  setHighestPriorityOpenNode,
} = nodesSlice.actions;

export default nodesSlice.reducer;
