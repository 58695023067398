import { Button, ButtonGroup, Box, HStack } from "@chakra-ui/react";
import { AccountModalContext } from "../../App";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AccountAvatar from "./AccountAvatar";

const AccountTeamOverlay = () => {
  const navigate = useNavigate();

  return (
    <>
    <Box position="fixed" top="5" right="20" zIndex="tooltip">
      <HStack spacing="4">
        <ButtonGroup variant="outline" isAttached>
          <Button
            fontWeight="normal"
            backgroundColor="white"
            onClick={() => navigate("/people/managers")}
          >
            Managers
          </Button>
          <Button
            fontWeight="normal"
            backgroundColor="white"
            onClick={() => navigate("/people/assignees")}
          >
            Asssignees
          </Button>
        </ButtonGroup>
      </HStack>
    </Box>
    <AccountAvatar />
    </>
  );
};

export default AccountTeamOverlay;
