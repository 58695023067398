// RadioCard.js
import { Box, useRadio } from "@chakra-ui/react";

function RadioCard({ isFirst, isLast, ...props }) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        _checked={{
          bg: "#EEF2F6", // Updated background color // Updated text color
          borderColor: "#266df0",
          borderWidth: isFirst ? "0 0 1px 0" : isLast ? "1px 0 0 0" : "0", // Conditional border width
        }}
        _focus={{
          boxShadow: "none", // Removes focus outline
        }}
        textAlign="center" // Center the text horizontally
        lineHeight="40px" // Center the text vertically
        width="200px"
        height="40px"
        borderRadius={isFirst ? "10px 0 0 0" : isLast ? "0 0 10px 0" : "0"} // Conditional border radius
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default RadioCard;
