import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addMasterplan as addMasterplanToDatabase,
  updateMasterplan as updateMasterplanInDatabase,
  deleteMasterplan as deleteMasterplanInDatabase,
  addEdgeToMasterplan as addEdgeToMasterplanInDatabase,
  addProjectToMasterplan as addProjectToMasterplanInDatabase,
  getAllMasterplans as getAllMasterplansFromDatabase,
} from "../../../services/firebaseService";
import { selectAllMasterplans } from "./selectors";

// Async thunk for adding a masterplan
export const addMasterplan = createAsyncThunk(
  "masterplan/addMasterplan",
  async (masterplanData, { rejectWithValue }) => {
    try {
      const newMasterplan = await addMasterplanToDatabase(masterplanData);
      return newMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEdgeFromMasterplan = createAsyncThunk(
  "masterplan/deleteEdgeFromMasterplan",
  async (
    { masterplanId, source, target, masterplanData },
    { rejectWithValue }
  ) => {
    try {
      // Remove the edge from masterplan.edges
      
      const updatedEdges = masterplanData.edges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );

      // Update the masterplan in the database without the edge
      const updatedMasterplan = await updateMasterplanInDatabase(masterplanId, {
        edges: updatedEdges,
      });

      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectFromMasterplan = createAsyncThunk(
  "masterplan/deleteProjectFromMasterplan",
  async (
    { masterplanId, projectData, masterplanData },
    { rejectWithValue }
  ) => {
    try {
      // Remove the project from masterplan.projects
      const updatedProjects = masterplanData.projects.filter(
        (project) => project !== projectData.projectId
      );

      // Update the masterplan in the database without the project
      const updatedMasterplan = await updateMasterplanInDatabase(masterplanId, {
        projects: updatedProjects,
      });

      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEdgeToMasterplan = createAsyncThunk(
  "masterplan/addEdgeToMasterplan",
  async ({ masterplanId, edgeData }, { rejectWithValue }) => {
    try {
      const updatedMasterplan = await addEdgeToMasterplanInDatabase(
        masterplanId,
        edgeData
      );
      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMasterplanEdges = createAsyncThunk(
  "masterplan/updateMasterplanEdges",
  async ({ masterplanId, newEdges, masterplanData }, { rejectWithValue }) => {
    try {
      // Update the masterplan in the database with the new edges
      const updatedMasterplan = await updateMasterplanInDatabase(masterplanId, {
        edges: newEdges,
      });

      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewMasterplanWithSelectedProjects = createAsyncThunk(
  "masterplan/addNewMasterplanWithAllProjects",
  async ({ projectIds }, { rejectWithValue }) => {
    try {
      const newMasterplan = {
        name: "New Masterplan",
        projects: projectIds,
        status: "active",
      };
      const addedMasterplan = await addMasterplanToDatabase(newMasterplan);
      return addedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMasterplan = createAsyncThunk(
  "masterplan/deleteMasterplan",
  async (masterplanId, { rejectWithValue }) => {
    try {
      // Delete the masterplan from the database
      await deleteMasterplanInDatabase(masterplanId);

      return masterplanId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMasterplan = createAsyncThunk(
  "masterplan/updateMasterplan",
  async ({ masterplanId, masterplanData }, { rejectWithValue }) => {
    try {
      // Update the masterplan in the database
      const updatedMasterplan = await updateMasterplanInDatabase(
        masterplanId,
        masterplanData
      );

      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const activateMasterplan = createAsyncThunk(
  "masterplan/activateMasterplan",
  async ({ masterplanId }, { dispatch, rejectWithValue }) => {
    try {
      const masterplans = await getAllMasterplansFromDatabase();
      const currentlyActivatedMasterplans = masterplans.filter(
        (masterplan) => masterplan.activated
      );
      const deactivatedMasterplans = currentlyActivatedMasterplans.map(
        (masterplan) => {
          return { ...masterplan, activated: false };
        }
      );
      await Promise.all(
        deactivatedMasterplans.map((masterplan) => {
          return updateMasterplanInDatabase(masterplan.masterplanId, {
            activated: false,
          });
        })
      );

      await updateMasterplanInDatabase(masterplanId, { activated: true });
      return masterplanId;
    } catch (error) {
      console.error("An error occurred during masterplan activation:", error);
      return rejectWithValue(error);
    }
  }
);

export const addProjectToMasterplan = createAsyncThunk(
  "masterplan/addProjectToMasterplan",
  async ({ masterplanId, projectId }, { rejectWithValue }) => {
    try {
      const updatedMasterplan = await addProjectToMasterplanInDatabase(
        masterplanId,
        { projectId: projectId }
      );
      return updatedMasterplan;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  masterplans: [],
  isLoading: false,
};

const masterplanSlice = createSlice({
  name: "masterplan",
  initialState,
  reducers: {
    setMasterplans: (state, action) => {
      state.masterplans = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateMasterplanName: (state, action) => {
      const { masterplanId, name } = action.payload;
      const masterplanIndex = state.masterplans.findIndex(
        (masterplan) => masterplan.masterplanId === masterplanId
      );
      if (masterplanIndex !== -1) {
        state.masterplans[masterplanIndex].name = name;
        updateMasterplanInDatabase(masterplanId, { name });
      }
    },
    deleteMasterplan: (state, action) => {
      const masterplanId = action.payload;
      state.masterplans = state.masterplans.filter(
        (masterplan) => masterplan.masterplanId !== masterplanId
      );
      deleteMasterplanInDatabase(masterplanId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMasterplan.fulfilled, (state, action) => {
        state.masterplans.push(action.payload);
      })
      .addCase(deleteEdgeFromMasterplan.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          state.masterplans[index] = updatedMasterplan;
        }
      })
      .addCase(deleteProjectFromMasterplan.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          state.masterplans[index] = updatedMasterplan;
        }
      })
      .addCase(addEdgeToMasterplan.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          state.masterplans[index] = updatedMasterplan;
        }
      })
      .addCase(updateMasterplanEdges.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          // Update the edges of the specific masterplan
          state.masterplans[index].edges = updatedMasterplan.edges;
        }
      })
      .addCase(addNewMasterplanWithSelectedProjects.fulfilled, (state, action) => {
        state.masterplans.push(action.payload);
      })
      .addCase(deleteMasterplan.fulfilled, (state, action) => {
        const masterplanId = action.payload;
        state.masterplans = state.masterplans.filter(m => m.masterplanId !== masterplanId);
      })
      .addCase(updateMasterplan.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          state.masterplans[index] = updatedMasterplan;
        }
      })
      .addCase(activateMasterplan.fulfilled, (state, action) => {
        const activatedMasterplanId = action.payload;
        // Set 'activated' to false for all masterplans
        state.masterplans.forEach(m => {
          m.activated = false;
        });
        // Set 'activated' to true for the specified masterplan
        const index = state.masterplans.findIndex(m => m.masterplanId === activatedMasterplanId);
        if (index !== -1) {
          state.masterplans[index].activated = true;
        }
      })
      .addCase(addProjectToMasterplan.fulfilled, (state, action) => {
        const updatedMasterplan = action.payload;
        const index = state.masterplans.findIndex(m => m.masterplanId === updatedMasterplan.masterplanId);
        if (index !== -1) {
          state.masterplans[index] = updatedMasterplan;
        }
      })
      .addCase(addMasterplan.rejected, (_, action) => {
        console.error("Failed to add masterplan:", action.error.message);
      })
      .addCase(deleteEdgeFromMasterplan.rejected, (_, action) => {
        console.error("Failed to delete edge from masterplan:", action.error.message);
      })
      .addCase(deleteProjectFromMasterplan.rejected, (_, action) => {
        console.error("Failed to delete project from masterplan:", action.error.message);
      })
      .addCase(addEdgeToMasterplan.rejected, (_, action) => {
        console.error("Failed to add edge to masterplan:", action.error.message);
      })
      .addCase(updateMasterplanEdges.rejected, (_, action) => {
        console.error("Failed to update masterplan edges:", action.error.message);
      })
      .addCase(addNewMasterplanWithSelectedProjects.rejected, (_, action) => {
        console.error("Failed to add new masterplan with selected projects:", action.error.message);
      })
      .addCase(deleteMasterplan.rejected, (_, action) => {
        console.error("Failed to delete masterplan:", action.error.message);
      })
      .addCase(updateMasterplan.rejected, (_, action) => {
        console.error("Failed to update masterplan:", action.error.message);
      })
      .addCase(activateMasterplan.rejected, (_, action) => {
        console.error("Failed to activate masterplan:", action.error.message);
      })
      .addCase(addProjectToMasterplan.rejected, (_, action) => {
        console.error("Failed to add project to masterplan:", action.error.message);
      });
      // Handle rejected and pending states as needed
      // ...
      ;
  },
});

export const { updateMasterplanName, setMasterplans, setLoading } =
  masterplanSlice.actions;

export default masterplanSlice.reducer;
