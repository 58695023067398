import {
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Spinner,
  Text,
  VStack,
  LinkBox,
} from "@chakra-ui/react";
import { CheckIcon, NotAllowedIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { selectNodesLoading } from "../../redux/features/nodes/selectors";
import {
  markNodeComplete,
  markNodeBlocked,
} from "../../redux/features/nodes/slice";
import { selectHighestPriorityNodeViaProjectIndexes } from "../../redux/features/projects/selectors";
import { SkippedNodesContext } from "../../App";
import React, { useContext, useEffect, useState } from "react";
import { selectProjectsLoading, selectProjectWithNode } from "../../redux/features/projects/selectors";
import RadioButtonsGroup from "./RadioButtonsGroup";
import {
  selectConfigOrder,
  selectFirstConfig,
} from "../../redux/features/config/selectors";
import { setOrderInDatabaseAndState } from "../../redux/features/config/slice";
import {
  selectActivatedMasterPlan,
  selectOpenProjectsInMasterplan,
  selectHighestPriorityNodeViaMasterplan,
} from "../../redux/features/masterplan/selectors";
import { useNavigate } from "react-router-dom";

const ActionButton = ({ icon, label, onClick, isLoading }) => (
  <IconButton
    aria-label={label}
    borderRadius="10px"
    borderWidth="1px"
    borderColor="#266df0"
    color="#266df0"
    icon={icon}
    onClick={isLoading ? undefined : onClick} // Disable the button when loading
    size="lg"
    fontWeight="normal"
    height="80px"
    width="66px"
    isDisabled={isLoading} // Disable the button when loading
  />
);

const useHandleSkip = (skippedNodes, setSkippedNodes) => {
  return (node) => {
    const newSkippedNodes = new Set(skippedNodes);
    newSkippedNodes.add(node.nodeId);
    setSkippedNodes(newSkippedNodes);
  };
};

const NextUpOverlay = () => {
  const isNodesLoading = useSelector(selectNodesLoading);
  const isProjectsLoading = useSelector(selectProjectsLoading);
  const configOrderSelected = useSelector(selectConfigOrder);
  // const [nextUpNode, setNextUpNode] = useState(null);
  const [configOrder, setConfigOrder] = useState(configOrderSelected);
  
  const [configIncrementer, setConfigIncrementer] = useState(0);

  // const { theOneThing, handleSkip } = useTheOneThing();
  const dispatch = useDispatch();

  const { skippedNodes, setSkippedNodes } = useContext(SkippedNodesContext);
  const handleSkip = useHandleSkip(skippedNodes, setSkippedNodes);
  const highestPriorityNodeViaProjectIndexes = useSelector((state) =>
    selectHighestPriorityNodeViaProjectIndexes(state, skippedNodes)
  );
  const highestPriorityNodeViaMasterplan = useSelector((state) =>
    selectHighestPriorityNodeViaMasterplan(state, skippedNodes)
  );

  const nextUpNode =
      configOrder === "projectOrder"
        ? highestPriorityNodeViaProjectIndexes
        : highestPriorityNodeViaMasterplan;

  useEffect(() => {
    setConfigOrder(configOrderSelected);
  }, [configOrderSelected]);

  const firstConfig = useSelector(selectFirstConfig);

  const handleChangeOrder = async (order) => {
    if (firstConfig && firstConfig.configId) {
      await dispatch(
        setOrderInDatabaseAndState({ id: firstConfig.configId, order: order })
      );
    }
    setConfigOrder(order);
    setConfigIncrementer((prev) => prev + 1);
  };

  const isLoading = isNodesLoading || isProjectsLoading;

  const handleMarkNodeComplete = async (node) => {
    await dispatch(markNodeComplete(node));
  };

  const handleMarkNodeBlocked = async (node) => {
    await dispatch(markNodeBlocked(node));
  };
  const navigate = useNavigate();

  const handleNextUpClick = () => {
    // Navigate to flow/project/project.projectID
    if (nextUpNode && nextUpNode.project && nextUpNode.project.projectId) {
      navigate(`/flow/project/${nextUpNode.project.projectId}`);
    }
  };

  return (
    <ButtonGroup
      isAttached
      variant="outline"
      position="fixed"
      bottom="25px"
      left="50%"
      transform="translateX(-50%)"
      borderRadius="0 10px 10px 0"
      borderWidth="0px"
      borderColor="#266df0"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      zIndex="docked"
      height="80px"
      paddingX="0px"
      bg="white"
    >
      <RadioButtonsGroup
        handleChangeOrder={handleChangeOrder}
        configOrder={configOrder}
      />
      <Button
        width="300px"
        color="#266df0"
        borderRadius="0 10px 10px 0"
        borderWidth="1px 1px 1px 0"
        borderColor="#266df0"
        textAlign="center"
        fontWeight="normal"
        height="80px"
        onClick={handleNextUpClick}
      >
        {isLoading ? (
          <Spinner size="xl" />
        ) : !nextUpNode ? (
          <Text
            noOfLines={3}
            whiteSpace="normal"
            overflowWrap="break-word"
            fontSize="md"
            fontWeight="bold"
          >
            Congrats! Inbox Zero. No More Open Tasks
          </Text>
        ) : (
          <Box
            display="flex"
            alignItems="center" // Vertical centering
            justifyContent="center" // Horizontal centering
            width="280px"
            height="100%"
            overflow="hidden"
          >
            <VStack spacing={1}>
            <Text
              noOfLines={2}
              whiteSpace="normal"
              overflowWrap="break-word"
              fontWeight={400}
              fontSize="md" // Adjust font size as needed
            >
              {nextUpNode.name}
              
            </Text>
            <Text
              noOfLines={2}
              whiteSpace="normal"
              fontWeight={600}
              overflowWrap="break-word"
              fontSize="md" // Adjust font size as needed
            >
              {nextUpNode && nextUpNode.project && nextUpNode.project.name}
              
            </Text>
            </VStack>
            
          </Box>
        )}
      </Button>
      <ActionButton
        label="Complete"
        icon={<CheckIcon />}
        isLoading={isLoading}
        onClick={() => handleMarkNodeComplete(nextUpNode)}
      />
      <ActionButton
        label="Mark Blocked"
        icon={<NotAllowedIcon />}
        onClick={() => handleMarkNodeBlocked(nextUpNode)}
        isLoading={isLoading}
      />
      <ActionButton
        label="Skip"
        icon={<ArrowRightIcon />}
        onClick={() => handleSkip(nextUpNode)}
        isLoading={isLoading}
      />
    </ButtonGroup>
  );
};

export default NextUpOverlay;
