import { configureStore } from "@reduxjs/toolkit";
import nodesReducer from "./features/nodes/slice";
import peopleReducer from "./features/people/slice"; // Import the people reducer
import projectsReducer from "./features/projects/slice";
import masterplanReducer from "./features/masterplan/slice";
import configsReducer from "./features/config/slice";
import { setMasterplans } from "./features/masterplan/slice";
import { authStateChangedListener } from "../services/authService";
import {
  getAllNodes,
  getAllPeople,
  getAllProjects,
  getAllMasterplans,
  getAllConfigs,
} from "../services/firebaseService";
import { setNodes } from "./features/nodes/slice";
import { setPeople } from "./features/people/slice"; // Import setPeople action
import { setProjects } from "./features/projects/slice";
import { setConfigs } from "./features/config/slice";

authStateChangedListener(async ({ loggedIn, user }) => {
    if (loggedIn) {
    const nodes = await getAllNodes();
    store.dispatch(setNodes(nodes));
    const people = await getAllPeople();
    store.dispatch(setPeople(people));
    const projects = await getAllProjects(); // Fetch projects data
    store.dispatch(setProjects(projects)); // Dispatch setProjects action
    const masterplans = await getAllMasterplans();
    store.dispatch(setMasterplans(masterplans));
    const configs = await getAllConfigs();
    store.dispatch(setConfigs(configs));
  } else {
    store.dispatch(setNodes([]));
    store.dispatch(setPeople([]));
    store.dispatch(setProjects([])); // Reset projects state
    store.dispatch(setMasterplans([]));
    store.dispatch(setConfigs([]));
  }
});

const rootReducer = {
  nodes: nodesReducer,
  people: peopleReducer,
  projects: projectsReducer, // Add projects reducer to the store
  masterplans: masterplanReducer,
  configs: configsReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
