import React from "react";
import { useSelector } from "react-redux";
import {
  Badge,
  HStack,
  VStack,
  Checkbox,
  Box,
  Text,
  Divider,
} from "@chakra-ui/react";
import NodeWrapper from "./NodeWrapper";
import CustomHandle from "./CustomHandle";
import { selectPersonById } from "../../../redux/features/people/selectors";

const NodeDisplay = ({
  node,
  backgroundColor,
  borderColor,
  handleCheckboxChange,
  openNodeModal,
}) => {
  const manager = useSelector((state) => selectPersonById(state, node.manager));
  const managerName = manager
    ? `${manager.firstName} ${manager.lastName.charAt(0)}.`
    : null;

  const assignee = useSelector((state) =>
    selectPersonById(state, node.assignee)
  );
  const assigneeName = assignee
    ? `${assignee.firstName} ${assignee.lastName.charAt(0)}.`
    : null;

  return (
    <NodeWrapper
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      openNodeModal={openNodeModal}
      node={node}
    >
      {manager && (
        <Badge
        variant="solid"
        fontSize="8px"
        position="absolute"
        backgroundColor={manager && manager.color ? manager.color : "grey"}
        top="-4"
        left="0"
        borderRadius="4px"
      >
          Manager: {managerName}
        </Badge>
      )}

      {assignee && (
        <Badge
          variant="solid"
          fontSize="8px"
          position="absolute"
          bottom="-4"
          right="0"
          borderRadius="4px"
          backgroundColor={assignee && assignee.color ? assignee.color : "grey"}
        >
          Assignee: {assigneeName}
        </Badge>
      )}

      <VStack maxHeight="100%" height="100%">
        <HStack
          flex={1}
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Checkbox
            isChecked={node.completed}
            onChange={handleCheckboxChange}
            size="lg"
            mt="8px"
            ml="8px"
            sx={{
              ".chakra-checkbox__control": {
                borderWidth: "1px",
                borderColor: borderColor,
                borderRadius: "md",
                boxShadow: "none",
                _checked: {
                  bg: borderColor,
                  borderColor: borderColor,
                  color: "white",
                },
                _focus: {
                  boxShadow: "none",
                },
              },
            }}
          />
          <Box pt="6px" pr="6px">
            <Text fontSize="14px" fontWeight="500" noOfLines={1}>
              {node.name}
            </Text>
          </Box>
        </HStack>
        <Divider width="95%" />
        <HStack
          flex={1}
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Text
            fontSize="10px"
            fontWeight="normal"
            color="grey"
            mb="8px"
            mt="-2px"
            mx="8px"
            noOfLines={2}
          >
            {node.description || ""}
          </Text>
        </HStack>
      </VStack>

      <CustomHandle type="target" position="left" borderColor={borderColor} />
      <CustomHandle type="source" position="right" borderColor={borderColor} />
    </NodeWrapper>
  );
};

export default NodeDisplay;
