import {
  collection,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "../firebaseConfig";
import { getAuth } from "firebase/auth";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Hardcoded user ID for development
// const devUserId = "l458y44IwpZdyfLGVtETPKzPYrF3";

// Gets the user ID - either the logged-in user's ID or the hardcoded one for development
function getUserId() {
  const auth = getAuth();
  return auth.currentUser?.uid || "";
}

//CONFIG

export async function getAllConfigs() {
  const userId = getUserId();
  const configsQuery = query(
    collection(db, "configs"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(configsQuery);
  return snapshot.docs.map((doc) => ({ ...doc.data(), configId: doc.id }));
}
// Firebase Service
export async function updateConfig(configId, updatedData) {
  const configRef = doc(db, "configs", configId);
  await updateDoc(configRef, updatedData);
  const updatedDoc = await getDoc(configRef);
  return {
    configId: updatedDoc.id,
    ...updatedDoc.data()
  };
}

//PROJECT

export async function getAllProjects() {
  const userId = getUserId();
  const projectsQuery = query(
    collection(db, "projects"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(projectsQuery);
  return snapshot.docs.map((doc) => ({ ...doc.data(), projectId: doc.id }));
}

export async function getProjectById(projectId) {
  const projectRef = doc(db, "projects", projectId);
  const snapshot = await getDoc(projectRef);
  return snapshot.exists()
    ? { ...snapshot.data(), projectId: snapshot.id }
    : null;
}

export async function addProject(projectData) {
  const projectsCollectionRef = collection(db, "projects");
  const newProjectRef = await addDoc(projectsCollectionRef, {
    ...projectData,
    userId: getUserId(),
  });

  // Extract the projectId from the newProjectRef
  const projectId = newProjectRef.id;

  // Optionally, construct the full project object to return
  const newProject = {
    ...projectData,
    projectId: projectId,
  };

  // Return the projectId or the full project object
  return newProject; // or return projectId if you only need the ID
}

export const deleteProject = async (projectId) => {
  const projectRef = doc(db, "projects", projectId);
  await deleteDoc(projectRef);
};

export async function addEdgeToProject(projectId, edgeData) {
  try {
    // Get the project
    const project = await getProjectById(projectId);
    if (!project) {
      throw new Error(`Project with ID ${projectId} not found.`);
    }

    // Add the edge to the project's edges array
    const updatedEdges = project.edges
      ? [...project.edges, edgeData]
      : [edgeData];

    // Update the project in the database
    await updateProject(projectId, { edges: updatedEdges });

    // Return the updated project
    return { ...project, edges: updatedEdges };
  } catch (error) {
    throw error;
  }
}

export async function updateProject(projectId, updatedData) {

  const projectRef = doc(db, "projects", projectId);

  // Perform the update
  await updateDoc(projectRef, updatedData);

  // Retrieve the updated document
  const updatedDoc = await getDoc(projectRef);

  // Return the updated project data
  return {
    projectId: updatedDoc.id,
    ...updatedDoc.data()
  };
}

export async function addNodeToProject(projectId, nodeData) {
  try {
    // Retrieve the project
    const project = await getProjectById(projectId); // Assuming you have a getProjectById function
    if (!project) {
      throw new Error(`Project with ID ${projectId} not found.`);
    }
    // Add the node to the project's nodes array
    const updatedNodes = project.nodes
      ? [...project.nodes, nodeData.nodeId]
      : [nodeData.nodeId];

    // Update the project in the database
    const updatedProjectAction = await updateProject(projectId, { nodes: updatedNodes });

    // Return the updated project
    return updatedProjectAction;
  } catch (error) {
    throw error;
  }
}

//MASTERPLAN

// Import necessary Firebase functions and initialize your db variable accordingly

export async function getAllMasterplans() {
  const userId = getUserId();
  const masterplansQuery = query(
    collection(db, "masterplans"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(masterplansQuery);

  return snapshot.docs.map((doc) => ({ ...doc.data(), masterplanId: doc.id }));
}

// Function to add a masterplan
export async function addMasterplan(masterplanData) {
  const masterplansCollectionRef = collection(db, "masterplans");
  const newMasterplanRef = await addDoc(masterplansCollectionRef, {
    ...masterplanData,
    userId: getUserId(), // Assuming you have a getUserId function to get the current user's ID
  });
  const masterplan = newMasterplanRef.id;
  const newMasterplan = {
    ...masterplanData,
    masterplanId: masterplan,
  };
  return newMasterplan;
}

// Function to update a masterplan
export async function updateMasterplan(masterplanId, updatedData) {
  const masterplanRef = doc(db, "masterplans", masterplanId);
  await updateDoc(masterplanRef, updatedData);
  const updatedDoc = await getDoc(masterplanRef);
  return {
    masterplanId: updatedDoc.id,
    ...updatedDoc.data()
  };
}
// Function to delete a masterplan
export const deleteMasterplan = async (masterplanId) => {
  const masterplanRef = doc(db, "masterplans", masterplanId);
  await deleteDoc(masterplanRef);
};

// Function to add an edge to a masterplan
export async function addEdgeToMasterplan(masterplanId, edgeData) {
  try {
    // Retrieve the masterplan
    const masterplan = await getMasterplanById(masterplanId); // Assuming you have a getMasterplanById function
    if (!masterplan) {
      throw new Error(`Masterplan with ID ${masterplanId} not found.`);
    }

    // Add the edge to the masterplan's edges array
    const updatedEdges = masterplan.edges
      ? [...masterplan.edges, edgeData]
      : [edgeData];

    // Update the masterplan in the database
    await updateMasterplan(masterplanId, { edges: updatedEdges });

    // Return the updated masterplan
    return { ...masterplan, edges: updatedEdges };
  } catch (error) {
    throw error;
  }
}

// Function to add a project to a masterplan
export async function addProjectToMasterplan(masterplanId, projectData) {
  try {
    // Retrieve the masterplan
    const masterplan = await getMasterplanById(masterplanId); // Assuming you have a getMasterplanById function
    if (!masterplan) {
      throw new Error(`Masterplan with ID ${masterplanId} not found.`);
    }

    // Add the project to the masterplan's projects array
    const updatedProjects = masterplan.projects
      ? [...masterplan.projects, projectData.projectId]
      : [projectData.projectId];

    // Update the masterplan in the database
    await updateMasterplan(masterplanId, { projects: updatedProjects });

    // Return the updated masterplan
    return { ...masterplan, projects: updatedProjects };
  } catch (error) {
    throw error;
  }
}

// You might also need to create getMasterplanById function based on getProjectById
export async function getMasterplanById(masterplanId) {
  const masterplanRef = doc(db, "masterplans", masterplanId);
  const snapshot = await getDoc(masterplanRef);
  return snapshot.exists()
    ? { ...snapshot.data(), masterplanId: snapshot.id }
    : null;
}

//NODES

export async function getAllNodes() {
  const userId = getUserId();
  const nodesQuery = query(
    collection(db, "nodes"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(nodesQuery);
  return snapshot.docs.map((doc) => ({ ...doc.data(), nodeId: doc.id }));
}

export async function getNodeById(nodeId) {
  const nodeRef = doc(db, "nodes", nodeId);
  const snapshot = await getDoc(nodeRef);
  return snapshot.exists() ? { ...snapshot.data(), nodeId: snapshot.id } : null;
}

export async function addNode(nodeData) {
  const nodesCollectionRef = collection(db, "nodes");
  const newNodeRef = await addDoc(nodesCollectionRef, {
    ...nodeData,
    userId: getUserId(), // Assuming you have a getUserId function to get the current user's ID
  });
  const nodeId = newNodeRef.id;
  //get Node
  const newNode = await getNodeById(nodeId);

  return newNode;
}

export async function addPath(pathData) {
  const pathsCollectionRef = collection(db, "paths");
  const newPathRef = await addDoc(pathsCollectionRef, {
    ...pathData,
    userId: getUserId(),
  });
  return newPathRef.id;
}

export async function updateNode(nodeId, updateData) {
  const nodeRef = doc(db, "nodes", nodeId);
  await updateDoc(nodeRef, updateData);
  // Fetch the updated node data get the updated node
  const updatedNode = await getNodeById(nodeId);

  // Return the updated node data
  return updatedNode;
}

export async function getAllPaths() {
  const userId = getUserId();
  const pathsQuery = query(
    collection(db, "paths"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(pathsQuery);
  return snapshot.docs.map((doc) => ({ ...doc.data(), pathId: doc.id }));
}

export async function getPathById(pathId) {
  const pathRef = doc(db, "paths", pathId);
  const snapshot = await getDoc(pathRef);
  return snapshot.exists() ? { ...snapshot.data(), pathId: snapshot.id } : null;
}

export async function updatePath(pathId, updateData) {
  const pathRef = doc(db, "paths", pathId);
  await updateDoc(pathRef, updateData);
}

export const deleteNode = async (node) => {
  const nodeRef = doc(db, "nodes", node.nodeId);
  await deleteDoc(nodeRef);
  return node;
};

export const deletePath = async (pathId) => {
  const pathRef = doc(db, "paths", pathId);
  await deleteDoc(pathRef);
};

// PEOPLE

export async function getAllPeople() {
  const userId = getUserId();
  const peopleQuery = query(
    collection(db, "people"),
    where("userId", "==", userId)
  );
  const snapshot = await getDocs(peopleQuery);
  return snapshot.docs.map((doc) => ({ ...doc.data(), personId: doc.id }));
}

export async function getPersonById(personId) {
  const personRef = doc(db, "people", personId);
  const snapshot = await getDoc(personRef);
  return snapshot.exists()
    ? { ...snapshot.data(), personId: snapshot.id }
    : null;
}

export async function addPerson(personData) {
  const peopleCollectionRef = collection(db, "people");
  const newPersonRef = await addDoc(peopleCollectionRef, {
    ...personData,
    userId: getUserId(),
  });
  return newPersonRef.id;
}

export async function updatePerson(personId, updateData) {
  const personRef = doc(db, "people", personId);
  await updateDoc(personRef, updateData);
  // Fetch the updated node data get the updated node
  const updatedPerson = await getPersonById(personId);
  return updatedPerson;
}


export async function deletePerson(personId) {
  const personRef = doc(db, "people", personId);
  await deleteDoc(personRef);
}
