import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Button,
  Text,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  signInWithGoogle,
  signOutUser,
  authStateChangedListener,
} from "../../services/authService";

const AccountModal = ({ isOpen, onClose }) => {
  const [user, setUser] = useState(null);
  const [isLoginAttempt, setIsLoginAttempt] = useState(false);

  useEffect(() => {
    const unsubscribe = authStateChangedListener(({ loggedIn, user }) => {
      setUser(loggedIn ? user : null);

      // Close the modal and reset login attempt state if a user successfully signs in
      if (loggedIn && isLoginAttempt) {
        onClose();
        setIsLoginAttempt(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [onClose, isLoginAttempt]);

  const handleSignInWithGoogle = async () => {
    setIsLoginAttempt(true); // Indicate a login attempt is in progress
    await signInWithGoogle();
    // The modal will be closed automatically in the useEffect if login is successful
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderWidth="2px" borderRadius="10px" margin={2}>
        <ModalHeader>Sign in with Google</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {user ? (
              <>
                <Text fontWeight="medium">{user.email}</Text>
                <Button colorScheme="blue" onClick={signOutUser}>
                  Logout
                </Button>
              </>
            ) : (
              <Button colorScheme="blue" onClick={handleSignInWithGoogle}>
                Sign in with Google
              </Button>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccountModal;
