import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPerson as addPersonToDatabase,
  updatePerson as updatePersonInDatabase,
  deletePerson as deletePersonInDatabase,
} from "../../../services/firebaseService";
import { removePersonFromNodes } from "../nodes/slice";
import { current } from "@reduxjs/toolkit";

// Async thunk for adding a person
export const addPerson = createAsyncThunk(
  "people/addPerson",
  async (personData, { rejectWithValue }) => {
    try {
      const newPersonId = await addPersonToDatabase(personData);
      return { ...personData, personId: newPersonId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePerson = createAsyncThunk(
  "people/deletePerson",
  async (personId, { dispatch }) => {
    await deletePersonInDatabase(personId);
    dispatch(removePersonFromNodes(personId));
    return personId;
  }
);

// Async thunk for updating a person
export const updatePerson = createAsyncThunk(
  "people/updatePerson",
  async ({personId, updatedData}, { rejectWithValue }) => {
    try {
      const updatedPerson = await updatePersonInDatabase(personId, updatedData);
      return updatedPerson;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  people: [],
  isLoading: false,
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setPeople: (state, action) => {
      state.people = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    // Other reducers will be the same
    updatePersonName: (state, action) => {
      const { personId, firstName, lastName } = action.payload;
      const personIndex = state.people.findIndex(
        (person) => person.personId === personId
      );
      if (personIndex !== -1) {
        state.people[personIndex].firstName = firstName;
        state.people[personIndex].lastName = lastName;
        updatePersonInDatabase(personId, { firstName, lastName });
      }
    },
    deletePerson: (state, action) => {
      const personId = action.payload;
      state.people = state.people.filter(
        (person) => person.personId !== personId
      );
      deletePersonInDatabase(personId);

      // Dispatch action to remove person from nodes
      removePersonFromNodes(personId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePerson.fulfilled, (state, action) => {
        const personId = action.payload;
        state.people = state.people.filter(
          (person) => person.personId !== personId
        );
      })
      .addCase(addPerson.fulfilled, (state, action) => {
        state.people.push(action.payload);
      })
      .addCase(addPerson.rejected, (state, action) => {
        // handle rejected case
        // You can handle the error here, for example, by setting an error state
      })
      .addCase(addPerson.pending, (state, action) => {
        // handle pending case
        // You can handle loading state here, for example
       }).addCase(updatePerson.fulfilled, (state, action) => {
          const updatedPerson = action && action.payload;
          const personIndex = state.people.findIndex(
            (person) => person.personId === updatedPerson.personId
          );
          if (personIndex !== -1) {
            state.people[personIndex] = updatedPerson;
          }
        });
    // Add other cases as needed
  },
});

export const { updatePersonName, setPeople, setLoading } = peopleSlice.actions;

export default peopleSlice.reducer;
