import { Box, HStack, Avatar } from "@chakra-ui/react";
import { AccountModalContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { authStateChangedListener } from "../../services/authService";

const AccountAvatar = () => {
    const { openAccountModal } = useContext(AccountModalContext);
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        const unsubscribe = authStateChangedListener(({ loggedIn, user }) => {
            setUser(loggedIn ? user : null);

            // Automatically open the Account Modal if no user is logged in
            if (!loggedIn) {
                openAccountModal();
            }
        });

        return () => {
            unsubscribe();
        };
    }, [openAccountModal]);

    return (
        <Box position="fixed" top="4" right="5" zIndex="tooltip">
            <HStack spacing="4">
                <Avatar background="black" size="md" name={user?.displayName} src={user?.photoURL} onClick={() => openAccountModal()} />
            </HStack>
        </Box>
    );
};

export default AccountAvatar;
