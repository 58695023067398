import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import firebaseConfig from "../firebaseConfig";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Implement the sign-in functionality
const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  } catch (error) {
    if (
      error.code === "auth/popup-closed-by-user" ||
      error.code === "auth/popup-blocked"
    ) {
      console.log(
        "Popup was closed or blocked. Please ensure popups are allowed and try again."
      );
    } else {
      console.error("Error signing in with Google:", error);
    }
  }
};

// Implement the sign-out functionality
const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

// Listen for authentication state changes
const authStateChangedListener = (callback) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      callback({ loggedIn: true, user });
    } else {
      callback({ loggedIn: false });
    }
  });

  // Return the unsubscribe function to allow the caller to stop listening for changes
  return unsubscribe;
};

export { signInWithGoogle, signOutUser, authStateChangedListener };
