import { Box, Card, Button, Text, Input, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectsNotInIds } from "../../redux/features/projects/selectors";
import { selectProjectIds } from "../../redux/features/masterplan/selectors";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { addProjectToMasterplan } from "../../redux/features/masterplan/slice";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { ForceUpdateContext } from "../../App";
import { addEmptyProjectWithNode } from "../../redux/features/projects/slice";
import { ProjectModalContext } from "../../App";

const getBorderColor = (status) => {
  switch (status) {
    case "completed":
      return "#2CC787";
    case "inactive":
      return "#D5D7D9";
    case "active":
      return "#266DF0";
    default:
      return "#D5D7D9"; // Default color
  }
};

const CardTray = ({
  style,
  masterplanId,
  nodes,
  setNodes,
  edges,
  setEdges,
}) => {
  const { forceUpdate, setForceUpdate } = useContext(ForceUpdateContext);
  const { openProjectModal } = useContext(ProjectModalContext);

  const projectIdsOfMasterplan = nodes.map((node) => node.id);
  const projects = useSelector((state) =>
    selectProjectsNotInIds(state, projectIdsOfMasterplan)
  );
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddClick = async () => {
    // make a new project and get the id
    const projectAction = await dispatch(addEmptyProjectWithNode());
    const project = projectAction.payload;
    // open the project edit modal with the new project id
    openProjectModal(project);
  };

  const handleOnDragEnd = (result) => {
    const projectId = result.draggableId;
    const positions = nodes.map((node) => node.position);
    const maxYPosition = Math.max(
      ...positions.map((position) => position.y),
      0
    );
    const newProjectPosition = { x: 0, y: maxYPosition + 130 };
    dispatch(addProjectToMasterplan({ masterplanId, projectId }));
    setNodes((nodes) => [
      ...nodes,
      {
        id: projectId,
        projectId: projectId,
        type: "custom",
        data: { label: projectId, projectId: projectId },
        position: newProjectPosition,
      },
    ]);
  };

  const sortedProjects = projects.sort((a, b) => {
    // Sort by status
    const statusOrder = ['active', 'inactive', 'completed'];
    const statusA = a.status || 'active';
    const statusB = b.status || 'active';
    if (statusOrder.indexOf(statusA) !== statusOrder.indexOf(statusB)) {
      return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
    }
  
    // Sort by index
    const indexA = a.index !== null ? a.index : Infinity;
    const indexB = b.index !== null ? b.index : Infinity;
    return indexA - indexB;
  });
  
  const filteredProjects = sortedProjects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
<Flex
    direction="column"
    justify="space-between"
    w="320px"
    h="710px"
    borderRadius="md"
    bg="white"
    border="1px solid #E2E8F0"
    p={4}
    style={style}
  >
    <Input
      placeholder="Search projects"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      mb={4}
    />
    <Flex
      direction="column"
      flex="1"
      overflowY="auto"
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="cardTray">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {filteredProjects.map((project, index) => (
                <Draggable
                  key={project.projectId}
                  draggableId={project.projectId.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      mb={4}
                      p={2}
                      border={`1px solid ${getBorderColor(project.status)}`}
                      borderRadius="md"
                    >
                      {project.name}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Flex>
    <Button
      onClick={handleAddClick}
      color="grey"
      fontWeight="normal"
      _hover={{ bg: "#D5D7D9", color: "black" }}
      colorScheme="grey"
      size="md"
      variant="outline"
      borderColor="#E2E8F0"
    >
      Add Project
    </Button>
  </Flex>
  );
};

export default CardTray;
