export const selectPeople = (state) => {
  if (!state || !state.people || !state.people.people) {
    return [];
  } else {
    return state.people.people;
  }
};

export const selectPersonById = (state, personId) => {
  const people = selectPeople(state);
  if (!people || !people.find || !personId) {
    return null;
  }
  return people.find((person) => person.personId === personId);
};

export const selectPeopleLoading = (state) => {
  return state.people.isLoading;
};

// New selector to select all people
export const selectAllPeople = (state) => selectPeople(state);
