import React from "react";
import { Box, Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { EditIcon, DragHandleIcon, StarIcon } from "@chakra-ui/icons";
import { Draggable } from "react-beautiful-dnd";
import { createPortal } from "react-dom";
import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { activateMasterplan } from "../../redux/features/masterplan/slice";

const DropdownItem = ({
  item,
  index,
  type,
  status,
  onEditClick,
  onActivatedClick,
  navigate,
}) => {
  const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.pointerEvents = "none";
      div.style.top = "0";
      div.style.width = "100%";
      div.style.height = "100%";
      self.elt = div;
      document.body.appendChild(div);
      return () => {
        document.body.removeChild(div);
      };
    }, [self]);

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === "fixed") {
          return createPortal(element, self.elt);
        }
        return element;
      };
  };

  const isActive =
    status === "active" || (status !== "completed" && status !== "inactive");

  const renderDraggable = useDraggableInPortal();

  const dispatch = useDispatch();

  const handleActivatedClick = (type, item) => {
    if (type === "masterplan") {
      dispatch(activateMasterplan({ masterplanId: item.masterplanId }));
    }
  };

  return (
    <Draggable
      key={item[`${type}Id`]}
      draggableId={item[`${type}Id`]}
      index={index}
    >
      {renderDraggable((provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          display="flex"
          alignItems="center"
          width="100%"
        >
          <ButtonGroup
            isAttached
            spacing="0"
            width="100%"
            borderRadius="md"
            _hover={snapshot.isDragging ? {} : { bg: "lightgrey" }}
          >
            <IconButton
              aria-label="Drag"
              icon={<DragHandleIcon />}
              size="sm"
              variant="ghost"
              {...provided.dragHandleProps}
              opacity={0}
              mx="0"
              mt="4px"
              _hover={{ opacity: 1, background: "transparent" }}
            />
            <Button
              onClick={() => navigate(`/flow/${type}/${item[`${type}Id`]}`)}
              flexGrow={1}
              justifyContent="flex-start"
              background="transparent"
              fontWeight="normal"
              px="0"
              maxWidth="calc(100% - 40px)" // Adjust the 40px value as needed
              isTruncated
              _hover={{ background: "transparent" }}
            >
              {item.name}
            </Button>
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              onClick={() => onEditClick(item)}
              size="md"
              variant="ghost"
              opacity={0}
              mx="0"
              mt="12px"
              verticalAlign="middle"
              height="100%"
              _hover={{ opacity: 1, background: "transparent" }}
            />
            {type === "masterplan" && isActive && item && (
              <IconButton
                aria-label="Star"
                icon={<StarIcon />}
                size="sm"
                variant="ghost"
                mx="0"
                height="40px"
                onClick={() => handleActivatedClick(type, item)}
                bg={item.activated ? "transparent" : "transparent"}
                opacity={item.activated ? 1 : 0}
                _hover={
                  !item.activated
                    ? { bg: "lightgrey", opacity: "1" }
                    : { bg: "grey" }
                }
              />
            )}
          </ButtonGroup>
        </Box>
      ))}
    </Draggable>
  );
};

export default DropdownItem;
