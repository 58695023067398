export const selectConfigs = (state) => {
  if (!state || !state.configs || !state.configs.config) {
    return [];
  } else {
    return state.configs.config;
  }
};

export const selectFirstConfig = (state) => {
  const configs = selectConfigs(state);
  if (configs && configs.length > 0) {
    return configs[0];
  } else {
    return null;
  }
};

export const selectConfigOrder = (state) => {
  const config = selectFirstConfig(state);
  if (config && config.order) {
    return config.order;
  } else {
    return null;
  }
};

export const selectAllConfigs = (state) => selectConfigs(state);
