import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NodeWrapper from './NodeWrapper';
import CustomHandle from './CustomHandle';
import NodeDisplay from './NodeDisplay';
import { NodeModalContext } from '../../../App'; // Adjust the import path as necessary
import {
  selectNodeById,
  selectNodesLoading,
  selectNodeStyleById,
} from '../../../redux/features/nodes/selectors';
import {
  markNodeComplete,} from '../../../redux/features/nodes/slice';

export const CustomNode = ({ data }) => {
  const dispatch = useDispatch();
  const node = useSelector((state) => selectNodeById(state, data.nodeId));
  const isLoading = useSelector(selectNodesLoading);
  const { backgroundColor, borderColor } = useSelector((state) => selectNodeStyleById(state, data.nodeId));
  const { openNodeModal, setOpenNodeModal } = useContext(NodeModalContext);


  const handleCheckboxChange = async (event) => {
   await dispatch(markNodeComplete(node));
  
  };

  if (isLoading || !node) return <div>Loading...</div>;

  return (
    <>
      <NodeDisplay
        node={node}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        openNodeModal={openNodeModal}
        handleCheckboxChange={handleCheckboxChange}
      />
      <CustomHandle type="target" position="left" borderColor={borderColor} />
      <CustomHandle type="source" position="right" borderColor={borderColor} />
    </>
  );
};

export default CustomNode;
