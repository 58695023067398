import {
    addEdgeToMasterplan,
    deleteEdgeFromMasterplan,
    deleteProjectFromMasterplan,
    updateMasterplanEdges,
  } from './slice';
  import { getIncomers, getOutgoers, getConnectedEdges } from 'react-flow-renderer';
  
  export const handleAddEdge = async (dispatch, masterplanId, params, setEdges, setForceUpdate) => {
    const { source, target } = params;
    const edge = { source, target, id: `e${source}-${target}` };
    await dispatch(addEdgeToMasterplan({ masterplanId, edgeData: edge }));
    setEdges((prevEdges) => [...prevEdges, edge]);
    setForceUpdate(prev => prev + 1);
  };
  
  export const handleDeleteEdge = async (dispatch, masterplanId, deletedEdges, edges, setEdges, masterplanData, setForceUpdate) => {
    await Promise.all(
      deletedEdges.map((edge) =>
        dispatch(deleteEdgeFromMasterplan({
          masterplanId,
          source: edge.source,
          target: edge.target,
          masterplanData
        }))
      )
    );
    const updatedEdges = edges.filter((edge) => !deletedEdges.some((delEdge) => delEdge.id === edge.id));
    setForceUpdate(prev => prev + 1);
    setEdges(updatedEdges);
  };
  
  export const handleDeleteNode = async (dispatch, masterplanId, deletedNodes, nodes, edges, setNodes, setEdges, masterplanData, setForceUpdate) => {
    await Promise.all(
      deletedNodes.map((node) =>
        dispatch(deleteProjectFromMasterplan({
          masterplanId,
          projectData: { projectId: node.id },
          masterplanData
        }))
      )
    );
    const updatedNodes = nodes.filter((node) => !deletedNodes.some((delNode) => delNode.id === node.id));
    setNodes(updatedNodes);
  
    const updatedEdges = await deletedNodes.reduce(async (accPromise, node) => {
      const acc = await accPromise;
      const incomers = getIncomers(node, nodes, edges);
      const outgoers = getOutgoers(node, nodes, edges);
      const connectedEdges = getConnectedEdges([node], edges);
  
      const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
      const createdEdges = incomers.flatMap((incomer) =>
        outgoers.map((outgoer) => ({
          id: `e${incomer.id}-${outgoer.id}`,
          source: incomer.id,
          target: outgoer.id
        }))
      );
  
      return [...remainingEdges, ...createdEdges];
    }, Promise.resolve(edges));
  
    await dispatch(updateMasterplanEdges({
      masterplanId,
      newEdges: updatedEdges,
      masterplanData
    }));
    setEdges(updatedEdges);
    setForceUpdate(prev => prev + 1);
  };  