import React, { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";
import { Droppable } from "react-beautiful-dnd";
import { CustomProjectNode } from "./CustomProjectNode";
import CustomEdge from "../FlowChart/CustomEdgeWithoutSearch";
import {
  selectProjects,
  selectProjectsLoading,
} from "../../redux/features/projects/selectors";
import {
  handleAddEdge,
  handleDeleteEdge,
  handleDeleteNode,
} from "../../redux/features/masterplan/actions";
import { processNodes } from "./ProcessNodes";
import { selectMasterplanById } from "../../redux/features/masterplan/selectors";
import { getLayoutedElements } from "./DagreGraphLayout";

const MasterplanFlow = ({ masterplanId, forceUpdate, setForceUpdate, nodes, setNodes, edges, setEdges }) => {
  const dispatch = useDispatch();
  const masterplan = useSelector(state => selectMasterplanById(state, masterplanId));
  const projectsData = useSelector(selectProjects);
  const projectsLoading = useSelector(selectProjectsLoading);

  const edgeTypes = { default: CustomEdge };

  useMemo(() => {
    if (forceUpdate === 0) {
      const processed = processNodes(masterplan, projectsData);
      setNodes(processed.nodes);
      setEdges(processed.edges);
    } else {
      const layouted = getLayoutedElements(nodes, edges);
      setNodes(layouted.nodes);
      setEdges(layouted.edges);
    }
  }, [masterplan, projectsData, forceUpdate]);

  const updateFlow = useCallback(() => setForceUpdate(prev => prev + 1), []);

  const onConnect = (params) => {
    handleAddEdge(dispatch, masterplanId, params, setEdges, updateFlow);
  };

  const onEdgesDelete = (deletedEdges) => {
    handleDeleteEdge(dispatch, masterplanId, deletedEdges, edges, setEdges, masterplan, updateFlow);
  };

  const onNodesDelete = (deletedNodes) => {
    handleDeleteNode(dispatch, masterplanId, deletedNodes, nodes, edges, setNodes, setEdges, masterplan, updateFlow);
  };

  if (projectsLoading) return <p>Loading...</p>;

  return (
    <Droppable droppableId="masterplanFlow">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps} style={{ position: "relative", height: "100vh" }}>
          <div style={{
          position: 'absolute',
          top: '24px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 50, // Ensure it's above the flow chart
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '20px',
          // Additional styles if needed
        }}>
          {masterplan?.name}
        </div>
          <ReactFlow
            key={forceUpdate}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            nodeTypes={{ custom: CustomProjectNode }}
            fitView
            onConnect={onConnect}
            onEdgesDelete={onEdgesDelete}
            onNodesDelete={onNodesDelete}
          >
            <MiniMap />
            <Controls />
            <Background color="#aaa" gap={16} />
          </ReactFlow>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default MasterplanFlow;
