import {
  selectProjectWithNode,
  selectOpenNodesInProject,
} from "../projects/selectors";

export const selectNodes = (state) => {
  if (!state || !state.nodes || !state.nodes.nodes) {
    return [];
  } else {
    return state.nodes.nodes;
  }
};

export const selectNodeById = (state, nodeId) => {
  const nodes = selectNodes(state);
  const node = nodes.find((node) => node.nodeId === nodeId);
  if (!node) {
    console.error(`Node with ID ${nodeId} not found.`);
    return null;
  }
  return node;
};

export const selectNodesByIds = (state, nodeIds) => {
  const nodes = selectNodes(state);
  const nodeIdsSet = new Set(nodeIds);
  return nodes.filter((node) => nodeIdsSet.has(node.nodeId));
};

export const selectNodesLoading = (state) => {
  return state.nodes.isLoading;
};

export const selectNodesByPersonType = (
  state,
  personType,
  personId,
  showCompleted
) => {
  const nodes = selectNodes(state);
  let personFiltered = [];
  if (!showCompleted) {
    personFiltered = nodes.filter(
      (node) => !node.completed && node[personType] === personId
    );
  } else {
    personFiltered = nodes.filter((node) => node[personType] === personId);
  }
  const personFilteredWithProjects = [];
  // filter the nodes with projects connected
  personFiltered.forEach((node) => {
    const project = selectProjectWithNode(state, node.nodeId);
    if (project && showCompleted) {
      if (project.status === "completed") {
        personFilteredWithProjects.push({
          ...node,
          project: project,
          completed: true,
        });
      } else {
        personFilteredWithProjects.push({ ...node, project: project });
      }
    } else if (project && !showCompleted && project.status !== "completed") {
      personFilteredWithProjects.push({ ...node, project: project });
    }
  });
  return personFilteredWithProjects;
};

export const selectAllNodesByPersonType = (
  state,
  personType,
  showCompleted,
  showOnlyOpen
) => {
  const nodes = selectNodes(state);
  let filteredNodes = [];
  if (nodes.length === 0 || !nodes.filter) return filteredNodes;
  if (!showCompleted) {
    filteredNodes = nodes.filter((node) => node[personType] && !node.completed);
  } else {
    filteredNodes = nodes.filter((node) => node[personType]);
  }

  const filteredNodesWithProjects = [];
  filteredNodes.forEach((node) => {
    const project = selectProjectWithNode(state, node.nodeId);
    if (showOnlyOpen) {
      if (project) {
        const openNodes = selectOpenNodesInProject(state, project);
        if (openNodes.length > 0 && openNodes.includes(node)) {
          filteredNodesWithProjects.push({ ...node, project: project });
        }
      }
    }
    else if (project && showCompleted) {
      if (project.status === "completed") {
        filteredNodesWithProjects.push({
          ...node,
          project: project,
          completed: true,
        });
      } else {
        filteredNodesWithProjects.push({ ...node, project: project });
      }
    } else if (project && !showCompleted && project.status !== "completed") {
      filteredNodesWithProjects.push({ ...node, project: project });
    }
  });

  return filteredNodesWithProjects;
};

export const selectNodesByManagerId = (state, personId) => {
  return state.nodes.nodes.filter((node) => node.manager === personId);
};

export const selectNodesByAssigneeId = (state, personId) => {
  return state.nodes.nodes.filter((node) => node.assignee === personId);
};

export const selectIsNodeBlockedToday = (state, nodeId) => {
  const node = selectNodeById(state, nodeId);

  if (!node || !node.latestBlockedDate) return false;

  const today = new Date().toISOString().split("T")[0];
  const latestBlockedDate = node.latestBlockedDate;
  return today <= latestBlockedDate;
};

export const selectNodeExists = (state, nodeId) => {
  const nodes = selectNodes(state);
  return nodes.some((node) => node.nodeId === nodeId);
};

export const selectNodeStyleById = (state, nodeId) => {
  const node = selectNodeById(state, nodeId);

  // Check if the node is not found
  if (!node) {
    return {
      backgroundColor: "white", // Default values for undefined node
      borderColor: "#D5D7D9",
      textColor: "black",
    };
  }
  const blocked = selectIsNodeBlockedToday(state, nodeId);

  let backgroundColor = "white"; // Default background color
  let borderColor;
  let textColor = "black"; // Default text color

  if (node.completed) {
    borderColor = "#2CC787";
    // backgroundColor for completed nodes
    // backgroundColor = "#90ee90"; // Optional: light green background for completed nodes
  } else if (blocked) {
    borderColor = "#F5B900";
    // backgroundColor for blocked nodes
    // backgroundColor = "#ffcc99"; // Optional: light orange background for blocked nodes
  } else {
    borderColor = "#D5D7D9"; // Default case for border color
  }

  return { backgroundColor, borderColor, textColor };
};

export const selectCompletedNodes = (state) => {
  const nodes = selectNodes(state);
  return nodes.filter((node) => node.completed);
};

export const selectBlockedNodes = (state) => {
  const nodes = selectNodes(state);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight

  return nodes.filter((node) => {
    const nodeDate = new Date(node.latestBlockedDate);
    nodeDate.setHours(0, 0, 0, 0); // Set time to midnight
    return nodeDate >= today;
  });
  return [];
};
