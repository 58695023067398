import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  VStack,
  Text,
  LinkBox,
  HStack,
  IconButton,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  selectNodesByManagerId,
  selectNodesByAssigneeId,
  selectNodesByPersonType
} from "../../redux/features/nodes/selectors";
import DisplayNodeGrid from "./DisplayNodeGrid";

const PersonCard = ({
  person,
  handleDeletePerson,
  viewMode,
  showCompleted,
  showOnlyOpen,
  openPersonModal,
}) => {

  const handleDoubleClick = () => {
    openPersonModal(person);
  };

  // Decide which nodes to display based on the view mode
  const displayNodes = person.nodes || [];

  return (
    <LinkBox
      as="article"
      w="full"
      role="group"
      position="relative"
    >
      <Flex justify="space-between" align="center">
        <Heading size="md" as="h3">
          <HStack spacing={1}>
            <Text fontWeight="400" fontSize="lg">{person.firstName} {person.lastName}</Text>
            {person.subtitle && (
              <Text fontSize="sm" color="gray.500" fontWeight="light">
                &bull; {person.subtitle}
              </Text>
            )}
            {person.location && (
              <Text fontSize="sm" color="gray.500" fontWeight="light">
                &bull; {person.location}
              </Text>
            )}
            <IconButton
              aria-label="Edit person"
              icon={<EditIcon />}
              size="sm"
              variant="ghost"
              onClick={() => {
                openPersonModal(person);
              }}
            />
          </HStack>
        </Heading>
      </Flex>

      <DisplayNodeGrid nodes={displayNodes} />
    </LinkBox>
  );
};

export default PersonCard;
