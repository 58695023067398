import { getLayoutedElements } from "./DagreGraphLayout"; // Make sure this path is correct

export const processNodes = (masterplan, projectsData) => {
  // Check if masterplan and projectsData are defined and have the expected structure
  if (!masterplan || !projectsData || !Array.isArray(masterplan.projects) || !Array.isArray(masterplan.edges)) {
    return { nodes: [], edges: [] };
  }

  const processedNodes = masterplan.projects
    .map((projectId) => {
      const project = projectsData.find((p) => p.projectId === projectId);
      if (!project) return null;

      return {
        id: project.projectId,
        type: "custom", // assuming you have a 'custom' node type
        data: {
          label: project.name || "Project",
          projectId: project.projectId,
        },
        position: { x: 0, y: 0 }, // initial position, will be layouted later
      };
    })
    .filter((node) => node !== null); // Remove any nulls from failed finds

  const processedEdges = masterplan.edges
    .map((edge) => ({
      id: `e${edge.source}-${edge.target}`,
      source: edge.source,
      target: edge.target,
      // You can add additional properties if needed
    }))
    .filter((edge) => edge !== null); // Safety check

  return getLayoutedElements(processedNodes, processedEdges);
};
