import {
  Button,
  ButtonGroup,
  Box,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectAllProjects,
  selectInactiveProjects,
  selectCompletedProjects,
  selectActiveProjects,
} from "../../redux/features/projects/selectors";
import {
  selectAllMasterplans,
  selectActiveMasterplans,
  selectInactiveMasterplans,
  selectCompletedMasterplans,
} from "../../redux/features/masterplan/selectors";
import GeneralAccordion from "./GeneralAccordion";
import ProjectModal from "./ProjectModal";
import React, { useState, useEffect } from "react";
import { addEmptyProjectWithNode } from "../../redux/features/projects/slice";
import { useDispatch } from "react-redux";
import { ProjectModalContext } from "../../App";
import { MasterplanModalContext } from "../../App";
import { useContext } from "react";
import { addNewMasterplanWithSelectedProjects } from "../../redux/features/masterplan/slice";
import { selectProjectByIndex } from "../../redux/features/projects/selectors";
import { activateMasterplan } from "../../redux/features/masterplan/slice";

const ProjectOverlay = () => {
  const navigate = useNavigate();
  const activeProjects = useSelector(selectActiveProjects); // Replace with your actual selector
  const completedProjects = useSelector(selectCompletedProjects); // Replace with your actual selector
  const inactiveProjects = useSelector(selectInactiveProjects); // Replace with your actual selector
  const activeMasterplans = useSelector(selectActiveMasterplans); // Replace with your actual selector
  const completedMasterplans = useSelector(selectCompletedMasterplans); // Replace with your actual selector
  const inactiveMasterplans = useSelector(selectInactiveMasterplans); // Replace with your actual selector
  const allMasterplans = useSelector(selectAllMasterplans); // Replace with your actual selector
  const firstIndexProject = useSelector((state) =>
    selectProjectByIndex(state, 0)
  );
  const firstIndexProjectId = firstIndexProject?.projectId;
  const [isMasterPlanDropdownOpen, setMasterPlanDropdownOpen] = useState(false);
  const [isProjectsDropdownOpen, setProjectsDropdownOpen] = useState(false);
  const dispatch = useDispatch();

  const { openProjectModal } = useContext(ProjectModalContext);
  const { openMasterplanModal } = useContext(MasterplanModalContext);

  const handleAddClick = async (type) => {
    if (type === "project") {
      // make a new project and get the id
      const projectAction = await dispatch(addEmptyProjectWithNode());
      const project = projectAction.payload;
      // open the project edit modal with the new project id
      openProjectModal(project);
    } else if (type === "masterplan") {
      const masterplanAction = await dispatch(
        addNewMasterplanWithSelectedProjects({
          projectIds: [firstIndexProjectId],
        })
      );
      const masterplan = masterplanAction.payload;
      openMasterplanModal(masterplan);
    }
  };

  const handleEditProject = (project) => {
    openProjectModal(project);
  };
  const handleEditMasterplan = (masterplan) => {
    openMasterplanModal(masterplan);
  };

  const closeDropdowns = (e) => {
    if (!e.target.closest(".menu-list")) {
      setMasterPlanDropdownOpen(false);
      setProjectsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isMasterPlanDropdownOpen || isProjectsDropdownOpen) {
      document.addEventListener("click", closeDropdowns);
    }

    return () => {
      document.removeEventListener("click", closeDropdowns);
    };
  }, [isMasterPlanDropdownOpen, isProjectsDropdownOpen]);

  return (
    <Box position="fixed" top="5" left="5" zIndex="tooltip">
      <ButtonGroup variant="outline" spacing="4">
      <Menu
          placement="right-start"
          onClose={() => setMasterPlanDropdownOpen(false)}
          onOpen={() => setMasterPlanDropdownOpen(true)}
        >
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            color="black"
            fontWeight="normal"
            _hover={{ bg: "#D5D7D9", color: "black" }}
            _expanded={{ bg: "#D5D7D9", color: "black" }}
            sx={{
              fontWeight: "normal",
              bg: "white",
            }}
          >
            Master Plan
          </MenuButton>
          <MenuList
            position="fixed"
            left={-151}
            top={55}
            width="276px"
            maxWidth="276px"
            maxHeight="50vh"
            overflowY="auto"
          >
            <GeneralAccordion
              activeItems={activeMasterplans}
              completedItems={completedMasterplans}
              inactiveItems={inactiveMasterplans}
              type="masterplan"
              onAddClick={handleAddClick}
              onEditClick={handleEditMasterplan}
            />
          </MenuList>
        </Menu>
        <Menu
          placement="right-start"
          onClose={() => setMasterPlanDropdownOpen(false)}
          onOpen={() => setMasterPlanDropdownOpen(true)}
        >
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            color="black"
            fontWeight="normal"
            _hover={{ bg: "#D5D7D9", color: "black" }}
            _expanded={{ bg: "#D5D7D9", color: "black" }}
            sx={{
              fontWeight: "normal",
              bg: "white",
            }}
          >
            Projects
          </MenuButton>
          <MenuList
            position="fixed"
            left={-284}
            top={55}
            width="276px"
            maxWidth="276px"
            maxHeight="50vh"
            overflowY="auto"
          >
            <GeneralAccordion
              activeItems={activeProjects}
              completedItems={completedProjects}
              inactiveItems={inactiveProjects}
              type="project"
              onAddClick={handleAddClick}
              onEditClick={handleEditProject}
            />
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Box>
  );
};

export default ProjectOverlay;
