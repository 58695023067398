import React from "react";
import { useSelector } from "react-redux";
import { VStack, Text, Box } from "@chakra-ui/react";
import { selectProjectById } from "../../redux/features/projects/selectors";
import { Handle } from "react-flow-renderer";
import { ProjectModalContext } from "../../App";
import { useContext } from "react";

const getBorderColor = (status) => {
  switch (status) {
    case 'completed':
      return '#2CC787';
    case 'inactive':
      return '#D5D7D9';
    case 'active':
      return '#266DF0';
    default:
      return '#D5D7D9'; // Default color
  }
};

export const CustomProjectNode = ({ data }) => {
  const project = useSelector((state) =>
    selectProjectById(state, data.projectId)
  );

  const { openProjectModal } = useContext(ProjectModalContext);

  if (!project) return <div>Loading...</div>;

  const handleDoubleClick = () => {
    openProjectModal(project);
  }

  return (
    <NodeWrapper
      backgroundColor="white" // Grey color
      borderColor={getBorderColor(project.status)}
      // borderColor="#D5D7D9" // Grey color
      handleDoubleClick={handleDoubleClick}
    >
      <VStack
        maxHeight="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {" "}
        {/* Add alignItems and justifyContent property */}
        <Box pt="6px" pr="6px">
          <Text fontSize="14px" fontWeight="normal" noOfLines={1}>
            {project.name}
          </Text>
        </Box>
      </VStack>
      <CustomHandle type="target" position="left" borderColor="#D5D7D9" />
      <CustomHandle type="source" position="right" borderColor="#D5D7D9" />
    </NodeWrapper>
  );
};


export default CustomProjectNode;

const CustomHandle = ({
  type,
  position,
  id = null,
  onConnect,
  borderColor,
}) => {
  let handleStyle = {
    opacity: 0,
  };

  if (position === "right") {
    handleStyle = {
      ...handleStyle,
      width: "8px",
      height: "8px",
      backgroundColor: "white",
      border: `1px solid ${borderColor}`,
      borderRadius: "50%",
      opacity: 1,
      top: "50%",
      transform: "translateY(-50%)",
    };
  }

  return (
    <Handle
      type={type}
      position={position}
      id={id}
      onConnect={onConnect}
      style={handleStyle}
    />
  );
};

const NodeWrapper = ({ children, backgroundColor, borderColor, handleDoubleClick }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: "10px",
        overflow: "hidden", // Prevents content from spilling outside
      }}
      onDoubleClick={handleDoubleClick} // Attach the double-click event handler
    >
      {children}
    </div>
  );
};
