import React from "react";
import { Accordion, MenuDivider, Button } from "@chakra-ui/react";
import ProjectOrMasterplanGroup from "./ProjectOrMasterplanGroup";

const GeneralAccordion = ({
  activeItems,
  completedItems,
  inactiveItems,
  type,
  onAddClick,
  onEditClick,
  onActivatedClick,
}) => {
  const activeExists = activeItems && activeItems.length > 0;
  const completedExists = completedItems && completedItems.length > 0;
  const inactiveExists = inactiveItems && inactiveItems.length > 0;

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      {activeExists && (
        <>
          <ProjectOrMasterplanGroup
            title="Active"
            items={activeItems}
            type={type}
            onEditClick={onEditClick}
          />
        </>
      )}
      {activeExists && inactiveExists && <MenuDivider />}
      {inactiveExists && (
        <ProjectOrMasterplanGroup
          title="Inactive"
          items={inactiveItems}
          type={type}
          onEditClick={onEditClick}
        />
      )}
      {inactiveExists && completedExists && <MenuDivider />}
      {completedExists && (
        <>
          <ProjectOrMasterplanGroup
            title="Completed"
            items={completedItems}
            type={type}
            onEditClick={onEditClick}
          />
        </>
      )}
      {(inactiveExists || completedExists || activeExists) && <MenuDivider />}
      <Button
        onClick={() => onAddClick(type)}
        color="black"
        fontWeight="normal"
        _hover={{ bg: "#D5D7D9", color: "black" }}
        colorScheme="grey"
        size="md"
        width="calc(100% - 8px)"
        ml={"4px"}
        mb={"-2px"}
        variant="outline"
        borderColor="white"
      >
        Add {type.charAt(0).toUpperCase() + type.slice(1)}
      </Button>
    </Accordion>
  );
};

export default GeneralAccordion;
