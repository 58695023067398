import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateConfig } from "../../../services/firebaseService";

const initialState = {
  config: {},
  isLoading: false,
};

// Thunk
export const setOrderInDatabaseAndState = createAsyncThunk(
  "config/setOrderInDatabaseAndState",
  async ({ id, order }) => {
    if (!id || typeof order !== "string" || order.length === 0) {
      throw new Error("Invalid input");
    }
    const updatedConfig = await updateConfig(id, { order });
    return updatedConfig
  }
);


const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfigs: (state, action) => {
      state.config = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    // Remove the setOrder reducer as it's no longer needed
  },
  extraReducers: {
    [setOrderInDatabaseAndState.pending]: (state) => {
      state.isLoading = true;
    },
    [setOrderInDatabaseAndState.fulfilled]: (state, action) => {
      const { id, order } = action.payload;
      if (state.config[id]) {
        state.config[id].order = order;
      }
      state.isLoading = false;
    },
    [setOrderInDatabaseAndState.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setConfigs, setLoading } = configSlice.actions;

export default configSlice.reducer;
