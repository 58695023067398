import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Input,
  Button,
  VStack,
  Center,
  Flex,
  Heading,
  useToken,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { selectAllPeople } from "../../redux/features/people/selectors";
import {
  addPerson,
  updatePersonName,
  deletePerson,
} from "../../redux/features/people/slice";
import PersonCard from "./PersonCard"; // Assuming PersonCard is in the same folder
import { useContext } from "react";
import { PersonModalContext } from "../../App";
import { selectAllNodesByPersonType } from "../../redux/features/nodes/selectors";
import { useMemo } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const PeoplePage = ({ viewMode }) => {
  const dispatch = useDispatch();
  const people = useSelector(selectAllPeople);
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [showCompleted, setShowCompleted] = useState(false);
  const [showOnlyOpen, setShowOnlyOpen] = useState(false);
  const [showPeopleWithoutDisplayNodes, setShowPeopleWithoutDisplayNodes] =
    useState(false);
  const { openPersonModal, setOpenPersonModal } =
    useContext(PersonModalContext);
  const [width80Percent] = useToken("sizes", ["80%"]);

  const managerNodes = useSelector((state) =>
    selectAllNodesByPersonType(state, "manager", showCompleted, showOnlyOpen)
  );

  const assigneeNodes = useSelector((state) =>
    selectAllNodesByPersonType(state, "assignee", showCompleted, showOnlyOpen)
  );

  const peopleWithNodes = people.map((person) => {
    const relatedNodes =
      viewMode === "manager"
        ? managerNodes.filter((node) => node.manager === person.personId)
        : assigneeNodes.filter((node) => node.assignee === person.personId);
    return { ...person, nodes: relatedNodes };
  });

  const { peopleWithDisplayNodes, peopleWithoutDisplayNodes } = useMemo(() => {
    return peopleWithNodes.reduce(
      (acc, personWithNodes) => {
        if (personWithNodes.nodes && personWithNodes.nodes.length > 0) {
          acc.peopleWithDisplayNodes.push(personWithNodes);
        } else {
          acc.peopleWithoutDisplayNodes.push(personWithNodes);
        }
        return acc;
      },
      { peopleWithDisplayNodes: [], peopleWithoutDisplayNodes: [] }
    );
  }, [peopleWithNodes]);

  const handleAddPerson = () => {
    if (newFirstName.trim() && newLastName.trim()) {
      dispatch(addPerson({ firstName: newFirstName, lastName: newLastName }))
        .then(() => {
          setNewFirstName("");
          setNewLastName("");
        })
        .catch((error) => {
          console.error("Failed to add person:", error);
        });
    }
  };

  const handleDeletePerson = (personId) => {
    dispatch(deletePerson(personId));
  };

  const headingText = viewMode === "manager" ? "Managers" : "Assignees";

  const handleAddClick = async () => {
    // make a new person and get the id
    const personAction = await dispatch(addPerson({ firstName: "First", lastName: "Last" }));
    const person = personAction.payload;
    // open the person edit modal with the new person id
    openPersonModal(person);
  }

  return (
    <Flex direction="column" h="100vh" pt={20} align="center">
      <VStack
        flex="1"
        overflowY="auto"
        w="80%"
        spacing={5}
        mt={3}
        align="center"
      >
        <Flex justify="space-between" w="full">
          <Heading size="lg" alignSelf="flex-start" width={"100%"}>
            {headingText}
            <Button 
            variant="outline" 
            ml="15px"
            onClick={handleAddClick}
            >
              Add Person
            </Button>
          </Heading>
          <VStack width="30vw" alignItem="right">
            <Flex justify="flex-end" alignItem="right">
              <FormControl display="flex" alignItems="right">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Show completed nodes?
                </FormLabel>
                <Switch
                  isChecked={showCompleted}
                  onChange={() => setShowCompleted(!showCompleted)}
                  colorScheme="teal"
                  size="md"
                />
              </FormControl>
            </Flex>
            <Flex justify="flex-end" alignItem="right">
              <FormControl display="flex" alignItems="right">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Show only open nodes?
                </FormLabel>
                <Switch
                  isChecked={showOnlyOpen}
                  onChange={() => setShowOnlyOpen(!showOnlyOpen)}
                  colorScheme="teal"
                  size="md"
                />
              </FormControl>
            </Flex>
          </VStack>
        </Flex>
        {peopleWithDisplayNodes.map((person) => (
          <PersonCard
            key={person.personId}
            person={person}
            handleDeletePerson={handleDeletePerson}
            viewMode={viewMode}
            showCompleted={showCompleted}
            openPersonModal={openPersonModal}
          />
        ))}
      </VStack>
      <Box w="80%" alignSelf="center" m={4}>
        <Button
          onClick={() =>
            setShowPeopleWithoutDisplayNodes(!showPeopleWithoutDisplayNodes)
          }
          w="full"
          rightIcon={
            showPeopleWithoutDisplayNodes ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )
          }
        >
          {showPeopleWithoutDisplayNodes ? "Hide" : "Show"} People Without Tasks
        </Button>

        {showPeopleWithoutDisplayNodes && (
          <VStack spacing={0} mt={4}>
            {peopleWithoutDisplayNodes.map((person) => (
              <PersonCard
                key={person.personId}
                person={person}
                handleDeletePerson={handleDeletePerson}
                viewMode={viewMode}
                showCompleted={showCompleted}
                showOnlyOpen={showOnlyOpen}
                openPersonModal={openPersonModal}
              />
            ))}
          </VStack>
        )}
      </Box>
    </Flex>
  );
};

export default PeoplePage;
