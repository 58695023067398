import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ButtonGroup,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Box,
  Menu,
  MenuButton,
  MenuList,
  HStack
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  updatePerson,
  deletePerson,
} from "../../redux/features/people/slice";
import { TwitterPicker } from 'react-color';

const PersonModal = ({ isOpen, onClose, person }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [location, setLocation] = useState("");
  const [color, setColor] = useState("#ABB8C3"); // Initialize with a default color
  const dispatch = useDispatch();

  const handleColorChange = (color) => {
    console.log(color.hex); // Log the selected color's hex value
    setColor(color.hex);
    onCloseMenu(); // Close the menu after color selection
  };

  useEffect(() => {
    if (person) {
      setFirstName(person.firstName);
      setLastName(person.lastName);
      setSubtitle(person.subtitle || "");
      setLocation(person.location || "");
      setColor(person.color || "#ABB8C3");
    } else {
      setFirstName("First");
      setLastName("Last");
      setSubtitle("");
      setLocation("");
      setColor("#ABB8C3");
    }
  }, [person]);

  const handlePersonUpdate = async () => {
    try {
      await dispatch(
        updatePerson({
          personId: person.personId,
          updatedData: { firstName, lastName, subtitle, location, color },
        })
      );
      onClose();
    } catch (error) {
      console.error("Error updating person:", error);
    }
  };

  const onCloseMenu = () => {
    // Logic to close the menu
  };

  const handleDelete = async () => {
    if (person) {
      await dispatch(deletePerson(person.personId));
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderWidth="2px" borderRadius="10px">
        <ModalHeader>
          <HStack spacing={3}>
            <Menu closeOnSelect={false}>
              <MenuButton as={Box} width="24px" height="24px" bg={color} borderRadius="md" />
              <MenuList>
                <TwitterPicker color={color} onChangeComplete={handleColorChange} />
              </MenuList>
            </Menu>
            <h2>Edit Person</h2>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} width="100%">
            <FormControl id="first-name">
              <FormLabel>First Name</FormLabel>
              <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl id="last-name">
              <FormLabel>Last Name</FormLabel>
              <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </FormControl>
            <FormControl id="subtitle">
              <FormLabel>Subtitle</FormLabel>
              <Input value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
            </FormControl>
            <FormControl id="location">
              <FormLabel>Location</FormLabel>
              <Input value={location} onChange={(e) => setLocation(e.target.value)} />
            </FormControl>
            <ButtonGroup spacing={2} width="100%">
              <Button colorScheme="blue" flex="1" onClick={handlePersonUpdate}>
                Save
              </Button>
              <Button colorScheme="red" flex="1" onClick={handleDelete}>
                Delete Person
              </Button>
            </ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PersonModal;