import {
  selectProjectsByIds,
  selectCompletedProjects,
  selectHighestPriorityNodeFromProjects,
} from "../projects/selectors";

export const selectMasterplans = (state) => {
  if (!state || !state.masterplans || !state.masterplans.masterplans) {
    return [];
  } else {
    return state.masterplans.masterplans;
  }
};

export const selectMasterplanById = (state, masterplanId) => {
  const masterplans = selectMasterplans(state);
  if (!masterplans || !masterplans.find || !masterplanId) {
    return null;
  }
  return masterplans.find((plan) => plan.masterplanId === masterplanId);
};

export const selectMasterplansLoading = (state) => {
  return state.masterplans.isLoading;
};

export const selectAllMasterplans = (state) => selectMasterplans(state);

export const selectActiveMasterplans = (state) => {
  const masterplans = selectMasterplans(state);
  return masterplans.filter(
    (masterplan) =>
      masterplan &&
      (masterplan.status === undefined ||
        (masterplan.status !== "inactive" && masterplan.status !== "completed"))
  );
};

export const selectInactiveMasterplans = (state) => {
  const masterplans = selectMasterplans(state);
  return masterplans.filter(
    (masterplan) => masterplan && masterplan.status === "inactive"
  );
};

export const selectCompletedMasterplans = (state) => {
  const masterplans = selectMasterplans(state);
  return masterplans.filter(
    (masterplan) => masterplan && masterplan.status === "completed"
  );
};

export const selectProjectIds = (state, masterplanId) => {
  const masterplan = selectMasterplanById(state, masterplanId);
  if (!masterplan || !masterplan.projects) {
    return [];
  }
  return masterplan.projects.map((project) => project);
};

export const selectActivatedMasterPlan = (state) => {
  const masterplans = selectMasterplans(state);
  return masterplans.find((masterplan) => masterplan && masterplan.activated);
};

// select open projects in masterplan
export const selectOpenProjectsInMasterplan = (state, masterplanId) => {
  const masterplan = selectMasterplanById(state, masterplanId);
  if (!masterplan || !masterplan.projects) {
    return []; // Return empty array if masterplan, projects, or edges are undefined
  }

  const projectIds = masterplan.projects;
  const edges = masterplan.edges;
  const masterplanProjects = selectProjectsByIds(state, projectIds) || [];
  const completedProjects = selectCompletedProjects(state);

  const possibleOpenProjects = masterplanProjects.length
    ? masterplanProjects.filter(
        (project) => !completedProjects.includes(project)
      )
    : [];

  const openProjects =
    possibleOpenProjects && possibleOpenProjects.length
      ? possibleOpenProjects.filter((project) => {
          if (!edges || edges.length === 0) {
            return true; // Return any project that is not completed when there are no edges
          }

          const incomingEdges = edges.filter(
            (edge) => edge.target === project.projectId
          );
          const incomingEdgeProjectIds = incomingEdges.map(
            (edge) => edge.source
          );
          return incomingEdgeProjectIds.every((projectId) => {
            const existingProject = masterplanProjects.find(
              (project) => project.projectId === projectId
            );
            return completedProjects.includes(existingProject);
          }); // Include projects with no incoming edges
        })
      : [];

  return openProjects;
};

const selectOpenProjectsInActivatedMasterplan = (state) => {
  const activatedMasterplan = selectActivatedMasterPlan(state);
  if (!activatedMasterplan) {
    return [];
  }
  return selectOpenProjectsInMasterplan(
    state,
    activatedMasterplan.masterplanId
  );
};

export const selectHighestPriorityNodeViaMasterplan = (state, skippedNodes) => {
  const openProjects = selectOpenProjectsInActivatedMasterplan(state);
  if (!openProjects || openProjects.length === 0) {
    return null;
  }

  return selectHighestPriorityNodeFromProjects(
    state,
    openProjects,
    skippedNodes
  );
};

// select an open node in open project in a masterplan
