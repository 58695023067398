import React, { useContext } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { NodeModalContext } from '../../App'; // Adjust the import path as necessary

const DisplayNodeGrid = ({ nodes }) => {
    const nodeModalContext = useContext(NodeModalContext);
    
    const handleNodeDoubleClick = (node) => {
        console.log("node in double click", node);
        nodeModalContext.openNodeModal(node);
    };
    
    return (
        <SimpleGrid columns={3} spacing={4} mt={4}>
            {nodes.map(node => (
                <Box
                    onDoubleClick={() => handleNodeDoubleClick(node)}
                    key={node.nodeId}
                    p={2}
                    borderWidth="1px"
                    rounded="md"
                    borderColor={node.completed ? "#62c48c" : "#D5D7D9"}
                    backgroundColor={node.completed ? "rgba(98, 196, 140, 0.2)" : "rgba(255, 255, 255, 1)"}
                    textAlign="center"
                >
                    <Text fontSize="md" fontWeight="400" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {node.name}
                    </Text>
                    <Text fontSize="md" fontWeight="600" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {node.project && node.project.name}
                    </Text>
                </Box>
            ))}
        </SimpleGrid>
    );
};

export default DisplayNodeGrid;
