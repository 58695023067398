import React from "react";
import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

const AccordionItemComponent = ({ title, children }) => (
  <AccordionItem border="none">
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel padding="5px ">{children}</AccordionPanel>
  </AccordionItem>
);

export default AccordionItemComponent;
